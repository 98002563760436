export const abbreviatesRole = role => {
  if (role?.includes("Desenvolvedor")) {
    return role.replace("Desenvolvedor", "Dev.");
  }

  if (role?.includes("Quality Assurance")) {
    return role.replace("Quality Assurance", "Q.A.");
  }

  if (role?.includes("Product Owner")) {
    return role.replace("Product Owner", "P.O.");
  }

  if (role?.includes("Arquiteto")) {
    return role.replace("Arquiteto", "Arq.");
  }

  return role;
};
