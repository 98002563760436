import { TextField } from '@material-ui/core';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import colors from '../../../../colors';

const colorConfig = colors.app.pages.auth.forgotPassword;

export const StyledModal = styled(Modal)`
  .modal-dialog {
    justify-content: center;
  }

  .modal-content {
    width: fit-content;
    max-width: 90%;
  }

  .modal-body {
    width: fit-content;
    max-width: 100%;
    padding: 24px;

    &::-webkit-scrollbar {
      width: 8px;
      background-color: ${colorConfig.styleModal.webkitScrollbarBackgroundColor};
      border-radius: 12px;
    }
    &::-webkit-scrollbar-thumb {
      background: ${colorConfig.styleModal.webkitScrollbarThumbColor};
      border-radius: 12px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  height: fit-content;
  margin: 0 auto;

  background: ${colorConfig.container.background};
  border-radius: 15px;

  span {
    display: block;
  }

  .forgot-title {
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;

    padding-bottom: 24px;
  }

  .forgot-subtitle {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    text-align: center;

    width: fit-content;
  }
`;

export const defaultInnerWidth = 90;

export const StyledInput = styled(TextField)`
  width: ${defaultInnerWidth + "%"};
  margin: 20px 0;

  input {
    font-size: 18px;
    padding: 7px 5px;
  }
`;

export const ButtonForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 20px;
  width: ${defaultInnerWidth + "%"};
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  width: 100%;
  max-width: 145px;
  margin: 0 !important;
  border-radius: 4px;
  border: 1px solid ${colorConfig.button.borderColor};
  background: ${({ color }) =>
    color === "primary" ? colorConfig.button.backgroundPrimaryColor : colorConfig.button.backgroundSecondaryColor};

  font-family: "Cera Pro Medium", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${({ color }) => (color === "primary" ? colorConfig.button.primaryColor : colorConfig.button.secondaryColor)};

  box-shadow: ${({ color }) =>
    color === "primary"
      ? "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)"
      : "none"};

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

export const PatternButton = `
  outline: none;
  border: none;
  margin: none;
  padding: 15px 25px;
  border-radius: 0.25rem;
  font-size: 14px;
  transition: all 0.2s linear;
`;

export const CancelButton = styled.button`
  ${PatternButton}
  background: ${colorConfig.cancelButton.background};
  cursor: ${props => props.disabled && "not-allowed"};
  color: ${colorConfig.cancelButton.color};

  &:hover {
    background: ${props => (props.disabled ? colorConfig.cancelButton.hoverDisabledBackground : colorConfig.cancelButton.hoverBackground)};
    color: ${colorConfig.cancelButton.color};
  }
`;

export const SubmitButton = styled.button`
  ${PatternButton}
  background: ${props =>
    props.disabled ? props?.theme?.disabledButton : colorConfig.submitButton.background};
  cursor: ${props => props.disabled && "not-allowed"};
  color: ${colorConfig.submitButton.color};
`;

export const LinkButton = styled(Link)`
  ${PatternButton}
  background: ${props =>
    props.disabled ? props?.theme?.disabledButton : colorConfig.submitButton.background};
  cursor: ${props => props.disabled && "not-allowed"};
  color: ${colorConfig.submitButton.color};
`;
