import moment from "moment";

const generateMonth = () => {
  const date = moment();
  date.add(1, 'month');

  return { month: date.month(), year: date.year() };
}

export default generateMonth;
