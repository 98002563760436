import styled from "styled-components";

export const ProjectCollapsedArea = styled.div`
  display: -webkit-inline-box;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
  width: 100%;

  & > label {
    font-family: "Montserrat SemiBold", sans-serif;
  }
`;

export const Card = styled.div`
  border: 1px solid
    ${({ color, colorInHover = true }) => (colorInHover ? "#c4c4c4" : color)};
  border-radius: 5px;
  padding: 6px 12px;
  width: 100%;
  height: fit-content;
  min-height: 40px;

  display: flex;
  flex-direction: ${({ column = false }) => (column ? "column" : "row")};
  align-items: left;

  .smile {
    margin-top: 8px;
    align-self: flex-start;
    color: ${({ color }) => color};
  }

  .header {
    max-width: 100%;

    label {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 60%;
    }
  }

  &.project {
    .highlightedText {
      font-weight: 700;
      color: ${({ color }) => color};
    }

    .smile {
      margin-top: 0px;
      padding: 3px 0;
    }

    .header {
      border-bottom: 0px;
      padding-bottom: 6px;
      justify-content: space-between;

      div:first-child {
        align-items: center;
        display: flex;
        gap: 10px;
      }

      label,
      span {
        font-family: "Montserrat";
        font-weight: 500;
        line-height: 18px;
        padding: 3px 0;
        margin-bottom: 0px;
      }

      span {
        font-size: 16px;
      }

      label {
        max-width: 85%;
        font-size: 18px;
        text-align: left;
      }
    }
  }

  .midContainer {
    margin-left: 8px;
    margin-top: 8px;

    h5 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      margin: 0;
      margin-bottom: 4px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      margin: 0;
    }
  }

  .rightContainer {
    display: flex;
    flex-direction: column;
    margin-left: auto;

    .icons {
      display: flex;
      margin-bottom: 4px;
      max-width: 10%;
      justify-content: space-between;

      .badge {
        position: relative;
        cursor: pointer;

        p {
          position: absolute;
          top: -2px;
          right: -3px;
          font-size: 9px;
          color: white;
          padding: 2px 4px;
          border-radius: 100%;
        }
      }
    }

    .hours {
      p {
        color: ${({ color }) => color};
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        margin: 0;
        text-align: center;
      }
    }
  }

  :hover {
    box-shadow: 3px 3px 3px #c4c4c4;
    border-color: ${({ color }) => (color ? `${color}` : null)};
  }

  border-left: ${({ color, emitBorderLeft = true }) =>
    color && emitBorderLeft ? `5px solid ${color}` : null};

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  .techs {
    max-width: 50%;

    .row {
      flex-wrap: wrap !important;
      justify-content: flex-start;
    }
  }

  span {
    padding: 4px 0;
  }
`;

export const Divider = styled.div`
  border-left: 1px solid #6d6d6d;
  margin: 0 5px;
`;

export const GroupedArrayShowMoreButton = styled.p`
  font-weight: 500;
  font-size: 14px;
  text-align: right;
  text-decoration-line: underline;
  line-height: 150%;
  color: #372652;
`;

export const GroupCardContainer = styled.div`
  width: 49%;
  gap: 10px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1900px) {
    width: 100%;
  }
`;
