import { debounce } from 'lodash';
import React from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';

import colors from '../../../../../colors';
import { VizzionApiService } from '../../../../services/VizzionApiService';

const colorConfig = colors.app.pages.auth.components.select2;

export class SelectSearchComponent extends React.Component {

  constructor(props) {
    super(props);
    this.api = new VizzionApiService();
  }

  getListFromValue = async (inputValue) => {
    let searchedList = [];
    if (inputValue && inputValue.length >= 3) {
      try {
        let searchedData = await this.api.makeHttpRequest({
          url: this.props.url + encodeURIComponent(inputValue)
        })

        if(this.props.convertList){
          searchedData = this.props.convertList(searchedData);
        }

        if (searchedData && searchedData.length > 0) {
          searchedData.forEach((item) => {
            const converted = this.props.convertObject(item);
            if (searchedList.indexOf(converted) === -1) {
              searchedList.push(converted)
            }
          });
        }
      } catch (error) {
        console.error(`Erro ao fazer a busca: ${error.message}`);
      }
    }

    return searchedList
  }

  promiseOptions = debounce((inputValue, callback) => {
    this.getListFromValue(inputValue)
      .then(callback);
  }, 500);

  render() {
    const { className, label, handleChange, value, newOption, required, resetInput, setNewOption, createLabelText, disabled, cacheOptions } = this.props;
    return (
      <AsyncCreatableSelect
        isClearable={true}
        isDisabled={disabled}
        value={resetInput ? "" : value}
        cacheOptions={cacheOptions}
        defaultOptions
        loadOptions={this.promiseOptions}
        placeholder={label}
        noOptionsMessage={() => "Nenhum encontrado"}
        loadingMessage={() => value && value.length >= 3 ? "Carregando..." : "Digite no mínimo 3 caractéres para pesquisar."}
        className={className}
        onChange={(e) => { handleChange(e); this.setState({ value: '' }) }}
        onInputChange={(e) => e && setNewOption ? setNewOption(true) : setNewOption ? setNewOption(false) : null}
        createOptionPosition={"first"}
        formatCreateLabel={(inputValue) => inputValue ? createLabelText ? createLabelText + inputValue : "Criar a organização: " + inputValue : inputValue}
        isValidNewOption={() => newOption}
        styles={required && required.status ? {
          control: base => ({
            ...base,
            borderColor: required.color ? required.color : colorConfig.borderColor,
          }),
          placeholder: base => ({ ...base, color: colorConfig.placeholderColor })
        } : { control: base => ({ ...base }), placeholder: base => ({ ...base, color: colorConfig.placeholderColor }) }}
      />
    );
  }
}
