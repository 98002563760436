export const StatusAlocacaoType = {
  POUCO_ALOCADO: "pouco_alocado",
  ALOCADO: "alocado",
  PARCIALMENTE_ALOCADO: "parcialmente_alocado",
  SUPER_ALOCADO: "super_alocado",
}

export const statusAlocacao = [
  {
    value: StatusAlocacaoType.POUCO_ALOCADO,
    label: "Pouco alocado"
  },
  {
    value: StatusAlocacaoType.ALOCADO,
    label: "Alocado"
  },
  {
    value: StatusAlocacaoType.PARCIALMENTE_ALOCADO,
    label: "Parcialmente alocado"
  },
  {
    value: StatusAlocacaoType.SUPER_ALOCADO,
    label: "Super alocado"
  }
];
