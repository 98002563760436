import React, { useEffect, useState } from "react";
import { Container, CustomSelect } from "./styles";
import { BiTrash } from "react-icons/bi";
import { HiCalendar, HiLockClosed, HiLockOpen } from "react-icons/hi";
import { GetContextLevelUserArea } from "../../../../../context/LevelUserAreaContext";
import { consumeContext } from "../../../../../utils/consumeContext";

const Component = ({
  index,
  levelId,
  level,
  options,
  updateLine,
  deleteLine,
  getLine,
  onLockVault
}) => {
  const line = getLine(level, index);
  const setIconAndColor = iconAndColor(line.lockVault);

  const onDelete = () => deleteLine(level, index);
  const onSelect = value => updateLine(level, index, value);
  const lockVault = idUser => onLockVault(idUser, level, index);

  const [hours, setHours] = useState(line.hours);
  const [startDate, setStartDate] = useState(line.startDate);

  const onInput = event => {
    const inputValue = event.target.value;

    if (event.target.id === "hoursInput")
      if (line.value) {
        //* obrigatorio inserir o usuario antes das horas
        setHours(inputValue);
        updateLine(level, index, { startDate, hours: inputValue });
      }

    if (event.target.id === "startDate") {
      if (line.value) {
        //* obrigatorio inserir o usuario antes da data de inicio
        setStartDate(inputValue);
        updateLine(level, index, { startDate: inputValue, hours });
      }
    }
  };

  function iconAndColor(lock) {
    if (lock === undefined) {
      return {
        color: "#AFAFAF",
        icon: <HiLockClosed />
      };
    }

    if (lock) {
      return {
        color: "#EC1C24",
        icon: <HiLockClosed />
      };
    }

    if (!lock) {
      return {
        color: "#6AB026",
        icon: <HiLockOpen />
      };
    }
  }

  return (
    <Container>
      <div className="inputs">
        <CustomSelect
          onChange={onSelect}
          classNamePrefix="SelectUserCard"
          className="select2-card"
          value={line}
          noOptionsMessage={() => "Nenhum usuário cadastrado nesse nível"}
          placeholder="Selecione"
          options={options.map(groups => ({
            ...groups,
            options: groups.options.filter(user => user.levelId === levelId)
          }))}
        />
        <div className="inputContainer">
          <label>Horas(mês)</label>
          <input
            style={{ maxWidth: "234px" }}
            type="text"
            value={line?.hours ?? 0}
            className="hoursInput"
            id="hoursInput"
            min={0}
            maxLength={3}
            datatype=""
            onChange={onInput}
            placeholder="Horas"
          />
        </div>
        <div className="inputContainer">
          <label>Dia de Início</label>
          <input
            style={{ maxWidth: "234px", width: "60px" }}
            type="text"
            value={line?.startDate ?? 1}
            id="startDate"
            min={0}
            maxLength={2}
            onChange={onInput}
            placeholder="Inicio"
            max={31}
          />
        </div>

        <button
          style={{ background: setIconAndColor.color, height: "38px" }}
          disabled={line.value && line.lockVault !== undefined ? false : true}
          onClick={() => {
            if (line.value) {
              const [id] = line.value.split(",");

              lockVault(parseInt(id));
            }
          }}
        >
          {setIconAndColor.icon}
        </button>

        <button
          style={{ background: "rgb(78, 75, 152)", height: "38px" }}
          onClick={() => onDelete()}
        >
          <BiTrash />
        </button>
      </div>
    </Container>
  );
};

const UserCard = consumeContext(Component, GetContextLevelUserArea);

export { UserCard };
