import React, { createContext, useContext, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { VizzionApiService } from "../../../../../services/VizzionApiService";
import Loading from "../../../../home/components/Loading";
import { generateStatus } from "../utils";

const AllocationContext = createContext({});

function AllocationProvider({ children }) {
  const api = new VizzionApiService();

  const [loading, setLoading] = useState(0);
  const [allocations, setAllocations] = useState([]);
  const [years, setYears] = useState([]);

  // Funcionários
  const [jobs, setJobs] = useState([]);
  const [employees, setEmployees] = useState([]);

  const [optionsSelectEmployees, setOptionsSelectEmployees] = useState([]);

  const [technologies, setUserTechnologies] = useState([]);

  //List All Projects
  const [projectsList, setProjectsList] = useState([]);

  const [months, setMonths] = useState([]);

  const date = useSelector(state => state.allocation);
  const [monthSelected, setMonthSelected] = useState(date.month);
  const [yearSelected, setYearSelected] = useState(date.year);

  const groupedOptions = [
    {
      label: "Employees",
      options: optionsSelectEmployees || []
    }
  ];

  const callbackApi = async (responsabilityFunction, func) => {
    try {
      await func();
    } catch (e) {
      Swal.fire(
        "Erro!",
        `Erro ao carregar os(as) ${responsabilityFunction}`,
        "error"
      );
    }
  };

  const callbackMultiApi = async arrayFunctions => {
    setLoading(true);
    await Promise.all(arrayFunctions);
    setLoading(false);
  };

  const loadAllocation = params => {
    if (!params) {
      params = date;
    }

    callbackApi("alocações", async () => {
      const response = await api.makeHttpRequest({
        url: "/allocation/list",
        params
      });

      setAllocations(
        response.map(proj => (
          {
            ...proj,
            status: generateStatus(
              proj?.allocation?.allocatedTime,
              proj?.allocation?.estimatedTime
            )
          }))
      );
    });
  };

  const loadMonthsByYear = year =>
    callbackApi("meses", async () => {
      const months = await api.makeHttpRequest({
        url: `allocation/months/${year ?? date.year}`
      });

      setMonths(
        months.map(month => ({
          labelMonth: month.monthDescription,
          alocadas: month.allocatedTime,
          planejadas: month.estimatedTime,
          month: month.month,
          faturamentoAlocacao: month.allocatedBilling,
          faturamentoProposta: month.estimatedBilling,
          custoAlocacao: month.allocatedCost,
          custoProposta: month.estimatedCost,
          totalProjetos: month.totalProjects,
          projetosFinalizados: month.finishedProjects,
        }))
      );
    });

  const loadProjects = year =>
    callbackApi("projetos", async () => {
      const projectsRes = await api.makeHttpRequest({
        url: `project/all`
      });
      setProjectsList(projectsRes);
    });

  const loadEmployeesJobsAndOptions = params =>
    callbackApi("funcionários, vagas e opções", async () => {
      if (!params) {
        params = {
          year: yearSelected,
          month: monthSelected
        };
      }

      const employees = await api.makeHttpRequest({
        url: "/allocation/employees",
        params
      });

      setEmployees(
        employees.map(emp => ({
          ...emp,
          status: generateStatus(
            emp?.allocations?.reduce(
              (sum, item) => sum + item.hoursAllocated,
              0
            ),
            emp?.tetoHorasMensais
          )
        }))
      );

      setOptionsSelectEmployees(
        employees.map(emp => ({
          value: `${emp.id},${emp.type.toUpperCase()}`,
          label: emp.name,
          levelId: emp.nivel,
          availableHours:
            emp.tetoHorasMensais -
            emp?.allocations?.reduce(
              (sum, item) => sum + item.hoursAllocated,
              0
            )
        }))
      );
    });

  const loadYearsWithAllocations = () =>
    callbackApi("anos", async () => {
      const years = await api.makeHttpRequest({
        url: "/allocation/years"
      });

      setYears(years.map(year => ({ label: year, value: year })));
    });

  const loadTechnologies = () =>
    callbackApi("tecnologias", async () => {
      const technologies = await api.makeHttpRequest({
        url: "/technology"
      });

      setUserTechnologies(
        technologies.map(tech => ({ value: tech.id, label: tech.nome }))
      );
    });

  React.useEffect(() => {
    callbackMultiApi([
      loadTechnologies(),
      loadEmployeesJobsAndOptions(),
      loadYearsWithAllocations(),
      loadMonthsByYear(),
      loadAllocation(),
      loadProjects()
    ]);
  }, []);

  const handleChangeYear = year => {
    callbackMultiApi([
      setYearSelected(year),
      loadAllocation({ year: year, month: monthSelected }), //? load de projetos
      loadMonthsByYear(year), //? load dos novos meses
      loadEmployeesJobsAndOptions({ year, month: monthSelected })
    ]);
  };

  const handleChangeMonth = month => {
    callbackMultiApi([
      setMonthSelected(month),
      loadAllocation({ year: yearSelected, month: month }), //? load de projetos
      loadEmployeesJobsAndOptions({ year: yearSelected, month })
    ]);
  };

  const reload = async func => {
    setLoading(true);
    await func();
    await callbackMultiApi([
      loadAllocation({ year: yearSelected, month: monthSelected }),
      loadEmployeesJobsAndOptions({ year: yearSelected, month: monthSelected }),
      loadMonthsByYear(yearSelected)
    ]);
    Swal.fire({
      title: "Sucesso!",
      text: "Dados carregados com sucesso!",
      icon: "success",
      onClose: () => {
        setLoading(false);
      }
    });
  };

  return (
    <AllocationContext.Provider
      value={{
        api,
        allocations,
        years,
        months,
        technologies,
        employees,
        projectsList,
        groupedOptions,
        monthSelected,
        yearSelected,
        jobs,
        optionsSelectEmployees,
        setLoading,
        handleChangeYear,
        handleChangeMonth,
        callbackApi,
        reload
      }}
    >
      <Loading isLoading={loading} />
      {children}
    </AllocationContext.Provider>
  );
}

const GetContextAllocation = () => useContext(AllocationContext);

export { AllocationProvider, GetContextAllocation };
