export const USER_TYPES = {
  ADMINISTRATOR: "ADMINISTRATOR",
  MANAGER: "MANAGER",
  DIRECTOR: "DIRECTOR",
  COORDINATOR: "COORDINATOR",
  EMPLOYEE: "EMPLOYEE",
  AUDITOR: "AUDITOR",
};

export const USER_TYPES_VIEW = {
  ADMINISTRATOR: "ADMINISTRADOR",
  MANAGER: "GERENTE",
  DIRECTOR: "DIRETOR",
  COORDINATOR: "COORDENADOR",
  EMPLOYEE: "FUNCIONÁRIO",
  AUDITOR: "AUDITOR",
};
