import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  Type: "Type Action",
  Comment: "Comment Action"
};

const INITIAL_STATE = {
  type: null,
  comment: null
}

export const insightReducer = persistReducer(
  { storage, key: "insight" },
  (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case actionTypes.Type: {
        return { ...state, type: action.payload };
      }
      case actionTypes.Comment: {
        return { ...state, comment: action.payload };
      }
      default:
        return state;
    }
  }
);

export const insightActions = {
  setTypeInsight: typeInsight => ({ type: actionTypes.Type, payload: typeInsight }),
  setComment: comment => ({ type: actionTypes.Comment, payload: comment })
};
