import React from "react";
import { FormattedMessage } from "react-intl";
import {toAbsoluteUrl} from "../../utils/utils";

export default class MenuItemText extends React.Component {
  render() {
    const { item, parentItem } = this.props;

    const style = !parentItem ? {
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
    } : {}

    return (
      <div style={{
          display: 'flex',
          width: '100%',
          ...style,
      }}>
        {
          item.icon ?
              item.icon.startsWith("custom-")
                  ? (
                      <i className={`kt-menu__link-icon ${item.icon}`}>
                        <img src={toAbsoluteUrl(`/media/icons/custom/${item.icon.replace("custom-", "")}.svg`)} alt="" />
                      </i>
                  )
                  : <i className={`kt-menu__link-icon ${item.icon}`} />
            : null
        }

        {parentItem && parentItem.bullet === "dot" && (
          <i className="kt-menu__link-bullet kt-menu__link-bullet--dot">
            <span />
          </i>
        )}

        {parentItem && parentItem.bullet === "line" && (
          <i className="kt-menu__link-bullet kt-menu__link-bullet--line">
            <span />
          </i>
        )}

        {item.translate ? (
          <span className="kt-menu__link-text" style={{ whiteSpace: "nowrap" }}>
            <FormattedMessage id={item.translate} defaultMessage={item.title} />
          </span>
        ) : (
          <span
            className="kt-menu__link-text"
            style={{ whiteSpace: "nowrap" }}
            dangerouslySetInnerHTML={{ __html: item.title }}
          />
        )}

        {item.badge && (
          <span className="kt-menu__link-badge" style={{color: '#f0f5ff'}}>
            <span className={`kt-badge ${item.badge.type}`} style={{color: '#f0f5ff'}}>
              {item.badge.value}
            </span>
          </span>
        )}

        {/*{item.submenu && <i className="kt-menu__ver-arrow la la-angle-right" />}*/}
      </div>
    );
  }
}
