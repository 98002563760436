import {VizzionApiService} from "./VizzionApiService";

export class ProjectService extends VizzionApiService {
  _endpoint= "/project";

  constructor() {
    super({});
  }

  async getSelectProjects(params) {
    return this.makeHttpRequest({
      url: `${this._endpoint}/select`,
      method: 'GET',
      params
    });
  }
}

