import React from "react";
import { Row } from "react-bootstrap";
import { generateColor } from "../../utils/generateColor";
import { renderSmileRespective } from "../../utils/renderSmilesRespective";
import { Card } from "../components";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { ProjectCard } from "./ProjectCard";
import { GetContextLevelUserArea } from "../../context/LevelUserAreaContext";
import ReactTooltip from "react-tooltip";

export const ProjectCardAllocation = ({ project }) => {
  const { color, type } = generateColor(
    project?.allocation?.allocatedTime ?? 0,
    project?.allocation?.estimatedTime ?? 0,
    true
  );

  const [collapsed, setCollapsed] = React.useState(true);

  const { lines, cancelChanges } = GetContextLevelUserArea();

  const allocated = lines.reduce((lineSum, lineItem) => {
    return (
      lineSum +
      lineItem?.lines?.reduce((itemSum, itemItem) => {
        return itemSum + +itemItem?.hours ?? 0;
      }, 0)
    );
  }, 0);

  const handleCloseCollapsed = () => {
    setCollapsed(!collapsed);

    if (!collapsed) {
      cancelChanges();
    }
  };

  const closeCollapsed = () => {
    setCollapsed(true);
  };

  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
  });

  return (
    <Card
      column
      color={color}
      colorInHover={false}
      className={`project ${collapsed && "collapsed"}`}
    >
      <ReactTooltip
        id={project.name}
        className="tooltip"
        effect="solid"
        place="top"
        type="light"
        border
      ></ReactTooltip>
      <>
        <Row className="header">
          <div style={{ width: "90%" }}>
            <div className="smile">{renderSmileRespective(type)}</div>
            <label data-tip={project.name} data-for={project.name}>
              {project?.name}
            </label>
          </div>
          <div className="actions">
            <button
              style={{ padding: "0 4px", position: "relative", top: "10px" }}
              onClick={handleCloseCollapsed}
            >
              {collapsed ? <IoIosArrowDown /> : <IoIosArrowUp />}
            </button>
          </div>
        </Row>
        {collapsed ? (
          <>
            <span>
              Custo{" "}
              <strong className="highlightedText">
                {formatter.format(project?.allocation.allocatedCost)} /{" "}
                {formatter.format(project?.allocation.estimatedCost)}
              </strong>
            </span>
            <span>
              Horas{" "}
              <strong className="highlightedText">
                {allocated}H / {project?.allocation?.estimatedTime}H
              </strong>
            </span>
            {project?.valorProposta ? (
              <span>
                Valor da Proposta:{" "}
                <strong className="highlightedText">
                  {formatter.format(project?.valorProposta)}
                </strong>
              </span>
            ) : null}
            {project?.horasVendidas ? (
              <span>
                Total de Horas Vendidas:{" "}
                <strong className="highlightedText">
                  {project?.horasVendidas}H
                </strong>
              </span>
            ) : null}
          </>
        ) : (
          <span style={{ marginLeft: "30px" }}>
            Horas alocadas:{" "}
            <strong className="highlightedText">{allocated}H </strong>
            Horas planejadas:{" "}
            <strong className="highlightedText">
              {project?.allocation?.estimatedTime}H
            </strong>
          </span>
        )}
      </>

      {!collapsed && (
        <ProjectCard project={project} closeCollapsed={closeCollapsed} />
      )}
    </Card>
  );
};
