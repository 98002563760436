export const EmployeeTypeOptions = [
  {
    value: 1,
    label: "Profissional Variável"
  },
  {
    value: 0,
    label: "Profissional Fixo"
  },
  {
    value: 2,
    label: "Profissional Freelancer"
  }
];
