import React from "react";
import { OverlayTrigger, Popover, Row } from "react-bootstrap";
import { FaUserAlt } from "react-icons/fa";
import { BsPlus } from "react-icons/bs";
import "./styles.scss";
import { UserCard } from "./UserCard";
import { GetContextLevelUserArea } from "../../../../context/LevelUserAreaContext";
import { MdInfo } from "react-icons/md";
import { PopoverBody } from "reactstrap";

export const LevelsCard = ({ level }) => {
  const { lines, addNewLine } = GetContextLevelUserArea();
  const item = lines[lines.findIndex(l => l.level === level.level)];

  return (
    <div className="collabs">
      <Row className="header">
        <FaUserAlt className="leading" />
        <h5>
          {level.level} - {item?.lines?.reduce((sum, i) => sum + +i.hours, 0) ?? 0}h / {item?.estimatedHours}h
        </h5>
        <button className="button" onClick={() => addNewLine(level.level)}>
          <BsPlus />
        </button>
        {item.estimatedHours !== item.lines.reduce((acc, curr) => acc + +curr.hours, 0) && (
            <OverlayTrigger
              trigger={['hover', 'focus']}
              overlay={(
                <Popover>
                  <PopoverBody>
                    <label>A somatória de horas dos usuários não condiz com a horas estimadas do nível</label>
                  </PopoverBody>
                </Popover>
              )}
            >
              <MdInfo style={{ color: 'red' }} />
            </OverlayTrigger>
        )}
      </Row>
      {item?.lines?.map((actualine, index) => (
        <UserCard
          key={actualine.value}
          level={level.level}
          levelId={item.levelId}
          index={index}
        />
      ))}
    </div>
  );
};
