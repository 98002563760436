import React, { createContext, useContext, useState } from "react";
import Swal from "sweetalert2";
import Loading from "../../../../home/components/Loading";
import { GetContextAllocation } from "./AllocationContext";

const LevelUserAreaContext = createContext();

function LevelUserAreaProvider({ children, project }) {
  const [loading, setLoading] = useState(0);

  const {
    api,
    monthSelected,
    yearSelected,
    groupedOptions,
    reload
  } = GetContextAllocation();

  const [lines, setLines] = React.useState(
    project.estimatedLevels.map(level => ({
      levelId: level.idLevel,
      level: level.level,
      estimatedHours: level.estimatedHours,
      lines: level.users.map(user => ({
        value: `${user.id},${user.type}`,
        label: user.name,
        hours: user.allocatedHours,
        lockVault: user.lockVault,
        startDate: user.startDate
      }))
    }))
  );
  const [responsavelSelected, setResponsavelSelected] = React.useState();

  const users = [];

  project.estimatedLevels.forEach(level =>
    level.users.forEach(user =>
      users.push(`${user.id},${user.type.toUpperCase()}`)
    )
  );

  //TODO verificar uma melhoria para disabilitar os usuario que vem do banco
  const [options, setOptions] = React.useState(
    groupedOptions.map(groups => ({
      ...groups,
      options: groups.options.map(opt => ({
        ...opt,
        isDisabled: false
        // !!users.find(u => u === opt.value) ao inves de false
      }))
    }))
  );

  const addNewLine = level => {
    const index = lines.findIndex(line => line.level === level);

    if (index > -1) {
      let newLines = [...lines];
      newLines[index].lines.push({ hours: 0 });
      setLines(newLines);
    }
  };

  const updateLine = (level, index, line) => {
    const indexLevel = lines.findIndex(line => line.level === level);

    if (indexLevel > -1) {
      let newLines = [...lines];
      const { estimatedHours } = newLines[indexLevel];

      const lineLevel = {
        estimatedHours,
        allocatedHours: newLines[indexLevel].lines.reduce(
          (sum, item) => sum + item.hours,
          0
        )
      };

      lineLevel.restToAllocate =
        lineLevel.estimatedHours - lineLevel.allocatedHours;

      const lineToUpdate = newLines[indexLevel].lines[index];

      if (line.hours) {
        line.hours = parseInt(line.hours);
      } else {
        if (!line.value) {
          line.hours = "0";
        } else if (line?.availableHours <= 0) {
          line.hours = 0;
        } else {
          lineLevel.restToAllocate += lineToUpdate?.hours;
          line.hours = Math.min(line.availableHours, lineLevel.restToAllocate);
        }
      }

      if (line.startDate) {
        line.startDate = parseInt(line.startDate);
      } else {
        if (!line.value) {
          line.startDate = "1";
        }
      }

      newLines[indexLevel].lines[index] = { ...lineToUpdate, ...line };
      setLines(newLines);

      if (line.value) {
        setOptions(prev => {
          let newOptions = [...prev];

          newOptions.forEach(i => {
            i.options.forEach(j => {
              if (j.value === lineToUpdate.value) {
                j.isDisabled = false;
              }

              if (j.value === line.value) {
                j.isDisabled = true;
              }
            });
          });

          return newOptions;
        });
      }
    }
  };

  const deleteLine = (level, index) => {
    const indexLevel = lines.findIndex(line => line.level === level);

    if (indexLevel > -1) {
      let newLines = [...lines];

      const userToDelete = newLines[indexLevel].lines[index];

      let indexOption;
      if (userToDelete?.value?.includes("USER")) {
        indexOption = 0;
      } else {
        indexOption = 1;
      }

      const indexUser = options[indexOption].options.findIndex(
        x => x.value === userToDelete.value
      );

      newLines[indexLevel].lines.splice(index, 1);
      setLines(newLines);

      setOptions(prev => {
        let newOptions = [...prev];

        if (indexUser && indexUser > -1)
          //* se nao encontrar o usuario nao tem como desabilitar (se ele for inativo mas tiver alocacao ele nao aparecera na lista de opcoes e dara erro)
          newOptions[indexOption].options[indexUser].isDisabled = false;

        return newOptions;
      });
    }
  };

  const getLine = (level, index) => {
    const indexLevel = lines.findIndex(line => line.level === level);

    if (indexLevel > -1) {
      return lines[indexLevel].lines[index];
    } else {
      return null;
    }
  };

  async function onLockVault(idUser, level, index) {
    setLoading(true);
    try {
      const indexLevel = lines.findIndex(line => line.level === level);

      if (indexLevel > -1) {
        let newLines = [...lines];

        const lineToUpdate = newLines[indexLevel].lines[index];

        const data = {
          idProject: project.idProject,
          month: monthSelected,
          year: yearSelected,
          idUser: idUser
        };

        const res = await api.makeHttpRequest({
          url: "/allocation/access-vault",
          method: "PATCH",
          data
        });

        newLines[indexLevel].lines[index] = {
          ...lineToUpdate,
          lockVault: res.lockVault
        };

        setLines(newLines);
      }
    } catch (e) {
      Swal.fire(
        "Erro",
        "Ocorreu um erro ao bloquear/desbloquear vault",
        "error"
      );
    } finally {
      setLoading(false);
    }
  }

  const saveChanges = () =>
    reload(async () => {
      const alocados = [];

      lines.forEach(line =>
        line?.lines?.forEach(user => {
          if (!user.value) return;
          const [id, type] = user.value.split(",");

          alocados.push({
            userId: type === "USER" ? id : null,
            jobId: type === "JOB" ? id : null,
            levelId: line.levelId,
            timeInHours: user.hours,
            startDate: user.startDate ?? 1
          });
        })
      );

      const data = {
        projectId: project.idProject,
        month: monthSelected,
        year: yearSelected,
        responsavelId: responsavelSelected?.value?.split(",")[0],
        alocados
      };

      try {
        await api.makeHttpRequest({
          url: "/allocation/alocarEmMassa",
          method: "PUT",
          data
        });
      } catch (e) {
        Swal.fire("Erro", "Ocorreu um erro ao alocar usuario", "error");
      }
    });

  const saveResponsavel = responsavel => setResponsavelSelected(responsavel);

  const cancelChanges = () => {
    setOptions(
      groupedOptions.map(groups => ({
        ...groups,
        options: groups.options.map(opt => ({ ...opt, isDisabled: false }))
      }))
    );
    setLines(
      project.estimatedLevels.map(level => ({
        level: level.level,
        estimatedHours: level.estimatedHours,
        lines: level.users.map(user => ({
          value: `${user.id},${user.type}`,
          label: user.name,
          hours: user.allocatedHours,
          startDate: user.startDate
        }))
      }))
    );
  };

  const sumHoursMatch = lines.every(line => line.estimatedHours === line.lines.reduce((sum, item) => sum + +item.hours, 0));

  return (
    <LevelUserAreaContext.Provider
      value={{
        lines,
        options,
        addNewLine,
        updateLine,
        deleteLine,
        getLine,
        saveResponsavel,
        saveChanges,
        cancelChanges,
        onLockVault,
        setLoading,
        sumHoursMatch
      }}
    >
      <Loading isLoading={loading} />
      {children}
    </LevelUserAreaContext.Provider>
  );
}

const GetContextLevelUserArea = () => useContext(LevelUserAreaContext);

export { LevelUserAreaProvider, GetContextLevelUserArea };
