import React from "react";
import { abbreviatesRole } from "../../../../../../utils/abbreviatesRole";

import { FiLayers, FiUser, FiSmile, FiFrown, FiMeh } from "react-icons/fi";
import { BiInfoCircle } from "react-icons/bi";
import { Card } from "../components";
import { generateColor } from "../../utils/generateColor";
import { renderSmileRespective } from "../../utils/renderSmilesRespective";

import ReactTooltip from "react-tooltip";
import { Row } from "react-bootstrap";
import { EmployeeTypeOptions } from "../../../../../../constants/EmployeeTypeOptions";

export const UserCardAllocation = ({ employee }) => {
  const allocatedHours = employee?.allocations?.reduce((sum, item) => {
    return sum + item.hoursAllocated;
  }, 0);

  const { color, type } = generateColor(
    allocatedHours ?? 0,
    employee?.tetoHorasMensais ?? 0,
    true
  );

  const object = groupBy(employee.allocations);
  const dataFor = `main-card-${employee.id}-${employee.type}`;
  const dataFor2 = `main-card2-${employee.id}-${employee.type}`;
  const abbreviateRole = abbreviatesRole(employee.papelDesempenhado);

  return (
    <Card color={color}>
      <ReactTooltip
        id={dataFor}
        className="tooltip"
        effect="solid"
        place="bottom"
        type="light"
        border
      >
        {object?.data && object.data.length > 0 ? (
          object.data.map(item => (
            <React.Fragment key={Math.random()}>
              <strong>
                {item.leadName === "Não existe líder desse projeto"
                  ? ""
                  : "Líder do projeto: "}
                {item.leadName}
              </strong>{" "}
              <br />
              {item.projects.map(project => (
                <React.Fragment key={Math.random()}>
                  <span>
                    {project.projectName} - {project.hoursAllocated}H
                  </span>{" "}
                  <br />
                </React.Fragment>
              ))}
            </React.Fragment>
          ))
        ) : (
          <strong>Nenhuma alocacao cadastrada</strong>
        )}
      </ReactTooltip>
      <ReactTooltip
        id={dataFor2}
        className="tooltip techs"
        effect="solid"
        place="bottom"
        type="light"
        border
      >
        <Row>
          {employee?.technologies && employee.technologies?.length > 0 ? (
            employee.technologies.map(item => (
              <div className="area-tech" key={Math.random()}>
                <span>{item}</span>
              </div>
            ))
          ) : (
            <strong>Nenhuma tecnologia</strong>
          )}
        </Row>
      </ReactTooltip>

      <div className="smile">{renderSmileRespective(type)}</div>

      <div className="midContainer">
        <h5>{employee?.name}</h5>
        <p>
          {abbreviateRole}
          {employee?.cargo !== abbreviateRole && employee?.cargo}
          {employee ? (
            <>
              /{" "}
              <strong>
                {
                  EmployeeTypeOptions.find(
                    type => type.value === employee.employeeType
                  ).label
                }
              </strong>
            </>
          ) : (
            ""
          )}
        </p>
      </div>
      <div className="rightContainer">
        <div className="icons">
          <div id="projectLeader">
            <div
              className="badge"
              //! 1° CONFIGURAÇÃO DE TOOLTIP
              data-for={dataFor}
              data-tip="1"
            >
              <FiUser />
              <p style={{ backgroundColor: color }}>
                {employee.numberTechLeads}
              </p>
            </div>
          </div>
          <div id="projects">
            <div className="badge" data-for={dataFor} data-tip="1">
              <FiLayers stroke="rgba(3, 29, 61, 1)" />
              <p style={{ backgroundColor: color }}>
                {employee.allocations?.length}
              </p>
            </div>
          </div>
          <div id="information">
            <div className="badge">
              <BiInfoCircle data-for={dataFor2} data-tip="1" />
            </div>
          </div>
        </div>
        <div className="hours">
          <p>
            {allocatedHours}H /{" "}
            <strong>{employee?.tetoHorasMensais ?? 0}H</strong>
          </p>
        </div>
      </div>
    </Card>
  );
};

const groupBy = allocations => {
  let leadFounded;
  let leadName;
  return {
    data: allocations?.reduce((list, sub) => {
      leadName =
        sub.leadName !== "" ? sub.leadName : "Não existe líder desse projeto";
      leadFounded = list.find(item => item.leadName === leadName);

      if (leadFounded) {
        list[list.indexOf(leadFounded)].projects.push({
          projectName: sub.projectName,
          hoursAllocated: sub.hoursAllocated
        });
      } else {
        list.push({
          leadName,
          projects: [
            { projectName: sub.projectName, hoursAllocated: sub.hoursAllocated }
          ]
        });
      }

      return list;
    }, [])
  };
};
