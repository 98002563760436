import React from "react";
import UserProfile from "../../../app/partials/layout/UserProfile";
import logo from '../../../app/assets/logo/logo-AtlanGroup.svg';


export default class Topbar extends React.Component {
  render() {
    return (
      <div className="kt-header__topbar">
        <UserProfile showBadge={true} />
        <div className="login-logo-container">
          <img
            alt="Logo Atlan Group"
            src={logo}
            className="login-logo-form"
          />
        </div>
      </div>
    );
  }
}
