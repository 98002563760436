import React from 'react';
import { FiFrown, FiMeh, FiSmile } from "react-icons/fi";
import { COLOR_TYPE } from "..";
import Help from "../../../../../assets/icons/help.svg"

export const renderSmileRespective = (type) => {
  switch(type){
    case COLOR_TYPE.RED:
      return <FiFrown />;
    case COLOR_TYPE.GREEN:
      return <FiSmile />;
    case COLOR_TYPE.ORANGE:
      return <FiMeh />;
    default:
      return <img src={Help} alt="help-icon" width={20} height={20} />;
  }
}
