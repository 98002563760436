import React from 'react';
import {Modal, Spinner, Col, Row} from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles/';

const useStyles = makeStyles(theme => ({
  modalBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  message: {
    fontSize: '1.1em'
  },
  spinnerBorder: {
    width: '3rem',
    height: '3rem'
  }
}));

export default function Loading(props) {
  function handleClose() {
    return false;
  }

  const classes = useStyles();

  return (
    <>
    {props.isLoading
      ?
        <Modal
          show={true}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="loading-modal"
          onHide={() => handleClose()}
        >
          <Modal.Body className={classes.modalBody}>
            <Row className="show-grid">
              <Col xs={12} md={12}>
                <h2>{props.msg ? props.msg : 'Carregando...'}</h2>
              </Col>
            </Row>
            <Row className="show-grid">
              <Col xs={12} md={12}>
                <p className={classes.message}>Aguarde um momento por favor</p>
              </Col>
            </Row>
            <Row className="show-grid">
              <Spinner animation="border" className={classes.spinnerBorder} size="xs" />
            </Row>
          </Modal.Body>
        </Modal>
      : null
    }
    </>
  )
}
