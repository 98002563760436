import {VizzionApiService} from "./VizzionApiService";

export class ReimbursementService extends VizzionApiService {
  _endpoint = "/reimbursement";

  constructor() {
    super({});
  }

  async create(data) {
    return this.makeHttpRequest({
      url: this._endpoint,
      method: 'POST',
      data
    });
  }

  async getById(id) {
    return this.makeHttpRequest({
        url: `${this._endpoint}/${id}`,
        method: 'GET'
    })
  }

  async getPaged(filter) {
    return this.makeHttpRequest({
      url: this._endpoint,
      method: 'GET',
      params: filter,
      paramsSerializer: params => {
        return Object.entries(params)
          .map(([key, value]) => `${key}=${value}`)
          .join('&');
      }
    });
  }

  async approve(id) {
    return this.makeHttpRequest({
      url: `${this._endpoint}/approve/${id}`,
      method: 'PUT',
    })
  }

  async reject(id, reason) {
    return this.makeHttpRequest({
      url: `${this._endpoint}/reject/${id}`,
      method: 'PUT',
      data: {
        reason
      }
    })
  }

  async approveAll(ids) {
    return this.makeHttpRequest({
      url: `${this._endpoint}/all/approve`,
      method: 'PUT',
      data: {
        reimbursementIds: ids
      }
    })
  }

  async rejectAll(ids, reason) {
    return this.makeHttpRequest({
      url: `${this._endpoint}/all/reject`,
      method: 'PUT',
      data: {
        reason,
        reimbursementIds: ids
      }
    })
  }

  async delete(id) {
    return this.makeHttpRequest({
        url: `${this._endpoint}/${id}`,
        method: 'DELETE'
    });
  }

  async update(id, data) {
    return this.makeHttpRequest({
        url: `${this._endpoint}/${id}`,
        method: 'PUT',
        data
    });
  }

  async uploadComprovants(data) {
    return this.makeHttpRequest({
      url: `${this._endpoint}/upload`,
      method: 'POST',
      data
    })
  }
}
