import { VizzionApiService } from '../../services/VizzionApiService';

export default async function (login, email) {
  const api = new VizzionApiService();
  return await api.makeHttpRequest({
    url: `/user/verificar-email-login?login=${login}&email=${email}`
  }).then(e => {
    return e;
  }).catch(() => {
    return false;
  });
}
