import { colors, COLOR_TYPE } from "..";

export const generateColor = (alocado, planejado, zeroIsRed) => {
  if (alocado === 0 && planejado === 0 && !zeroIsRed)
    return { color: colors[COLOR_TYPE.GRAY], type: COLOR_TYPE.GRAY };

  const percentage = (alocado * 100) / planejado;

  switch (true) {
    case percentage >= 110:
      return { color: colors[COLOR_TYPE.BLACK], type: COLOR_TYPE.BLACK };
    case percentage >= 80:
      return { color: colors[COLOR_TYPE.GREEN], type: COLOR_TYPE.GREEN };
    case percentage >= 60:
      return { color: colors[COLOR_TYPE.ORANGE], type: COLOR_TYPE.ORANGE };
    default:
      return { color: colors[COLOR_TYPE.RED], type: COLOR_TYPE.RED };
  }
};
