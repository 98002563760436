// Base colors
const PRIMARY_1 = "#000000";
const PRIMARY_2 = "#333333";
const PRIMARY_3 = '#6D6D6D'
const PRIMARY_4 = "#00a4b2";
const PRIMARY_5 = '#f7f7f7';
const PRIMARY_6 = '#e0e1dd'
const PRIMARY_9 = "#031d3d";
const PRIMARY_10 = '#d18120';
const PRIMARY_11 = '#f5f5f5';
const PRIMARY_12 = '#cbd5e0';
const PRIMARY_13 = "#FFFFFF";
const PRIMARY_14 = '#031d3d';
const PRIMARY_15 = '#4E4B98';
const PRIMARY_16 = '#ABC616';
const PRIMARY_17 = '#889d11';
const PRIMARY_18 = '#81AE38';
const PRIMARY_19 = '#478F00';
const PRIMARY_20 = '#6AB026';
const PRIMARY_21 = '#45659D';
const PRIMARY_22 = '#254A7B';
const PRIMARY_23 = '#8FB435';
const PRIMARY_24 = '#769728';

const SECONDARY_2 = '#00A4B2';
const SECONDARY_3 = '#9a9a9a';
const SECONDARY_4 = '#625FA4';
const SECONDARY_5 = '#4E4B98';
const SECONDARY_9 = '#1c0c3f';
const SECONDARY_10 = '#d1d1d1';
const SECONDARY_11 = '#e6e6e6';
const SECONDARY_12 = '#e64646';
const SECONDARY_13 = '#9C090F';
const SECONDARY_14 = '#C63C3C';
const SECONDARY_15 = "#d93438";
const SECONDARY_16 = '#FD9C26';
const SECONDARY_17 = '#3B66A1';
const SECONDARY_18 = '#FB4949';
const SECONDARY_19 = '#C33131';


// app/pages/admin/pages/dashboard
const colorConfiguration = {
  app: {
    pages: {
      admin: {
        pages: {
          reimbursementForm: {
            PRIMARY_1: PRIMARY_21,
            PRIMARY_2: PRIMARY_22,
            SECONDARY_1: SECONDARY_17,
            APPROVE: PRIMARY_23,
            REJECT: SECONDARY_18,
            APPROVE_HOVER: PRIMARY_24,
            REJECT_HOVER: SECONDARY_19,
          },
          parameters: {
            PRIMARY_1: PRIMARY_21,
          },
          allocation: {
            black: '#333333',
            red: SECONDARY_14,
            gray: 'gray',
            green: PRIMARY_20,
            PRIMARY_1: PRIMARY_2,
            orange: SECONDARY_16,
            SECONDARY_3: PRIMARY_3,

            redAlternative: SECONDARY_13,
            greenAlternative: PRIMARY_19,
            genericAlternative: PRIMARY_10,

            components: {
              projectCardAllocation: {
                projectCard: {
                  button: SECONDARY_10,
                  active: SECONDARY_5,
                  cancel: SECONDARY_5,
                  levelsCard:{
                    userCard: {
                      container: PRIMARY_6,
                      button: PRIMARY_3,
                      input: PRIMARY_3
                    }
                  }
                },
              },
              header: {
                border: PRIMARY_3
              }
            }
          },
          classes: {
            categories: {
              components: {}
            }
          },
          gerenciarCandidatos: {
            alocarUsuariosContratos:{
              text: PRIMARY_14,
              status: PRIMARY_13,
              info: PRIMARY_15,
              style: PRIMARY_18,
            }
          },
          insights: {
            components:{
              sections:{
                projetos: PRIMARY_16,
                melhoriaProcesso: PRIMARY_20,
                reclamacoes: SECONDARY_2,
                eventos: PRIMARY_15,
              }
            }
          },
          profileEditing: {
            profile: PRIMARY_16,
            avatar: PRIMARY_17,
            span: SECONDARY_12,
            h2: PRIMARY_5,
            userAvatarWithNameNotUpdate: PRIMARY_17,
            label: PRIMARY_13,
            navigationSlide: PRIMARY_15,
            containerSlide: {
              background: PRIMARY_12,
              button: PRIMARY_12,
              svg: PRIMARY_15
            }
          },
          projects: {
            projectForm:{
              cellFull: PRIMARY_11
            }
          },
          propostaTrabalho: {
            circumferenceContainer: PRIMARY_4,
            button: PRIMARY_13
          },
          verzelBi: {},

          dashboard: {
            components: {
              dashClient: {
                saudeProjetos: {
                  backgroundColor: PRIMARY_13,
                },
                title: {
                  backgroundColor: SECONDARY_9,
                  color: PRIMARY_13
                },
                container: {
                  content: {
                    pColor: PRIMARY_3,
                    welcomeColor: PRIMARY_9,
                    valorGastoMes: {
                      backgroundColor: PRIMARY_13,
                      valorGastoColor: PRIMARY_9
                    },
                    horasTotaisMes: {
                      backgroundColor: PRIMARY_13,
                      horasEntreguesPlanejadas: PRIMARY_9
                    },
                    progressoProjeto: {
                      backgroundColor: PRIMARY_13,
                      porcentagemColor: PRIMARY_9
                    },
                    bottomCardsTitle: {
                      pColor: PRIMARY_9,
                      clockwork: {
                        backgroundColor: PRIMARY_13,
                        borderColor: SECONDARY_11,
                        borderHoverColor: SECONDARY_4,
                        svgColor: SECONDARY_4,
                      }
                    },
                    historyTitleColor: PRIMARY_9,
                    areaHistory: {
                      backgroundColor: PRIMARY_13,
                      span0Color: PRIMARY_4,
                      span1Color: SECONDARY_5
                    },
                  }
                },
                customStyles: {
                  control: {
                    borderColor: PRIMARY_13,
                    color: PRIMARY_9
                  },
                  option: {
                    backgroundColor: PRIMARY_13,
                    focusedBackgroundColor: PRIMARY_4,
                    color: PRIMARY_9,
                    focusedColor: PRIMARY_13
                  },
                  multiValue: {
                    color: PRIMARY_13,
                    backgroundColor: PRIMARY_4,
                  }
                }
              },
              cardProject: {
                container: {
                  projectTitleColor: PRIMARY_9,
                  projectCardBackgroundColor: PRIMARY_13,
                  modalTxt: {
                    color: PRIMARY_16,
                    hoverColor: PRIMARY_3,
                  }
                }
              },
              cardDashboard: {
                cardInfo: {
                  saudeProjetoColor: PRIMARY_4,
                  custoProjetoColor: SECONDARY_5,
                  salarioProjetoColor: SECONDARY_5,
                }
              },
              dashPlaceHolder: {
                styledCardPlaceHolders: {
                  titleWrapper: {
                    textRowColor: SECONDARY_3,
                  },
                  upperCardsWrapper: {
                    background: {
                      rectShapeColor: SECONDARY_3,
                    },
                    upperCardContent: {
                      text: {
                        textRowColor: PRIMARY_1,
                      },
                      placeholderChart: {
                        rectShapeColor: PRIMARY_1,
                      },
                      textMiddle: {
                        textRowColor: PRIMARY_1,
                      },
                      placeholderChartCircle: {
                        roundShapeColor: PRIMARY_1,
                      }
                    }
                  },
                  filtersWrapper: {
                    textRowColor: SECONDARY_3,
                  },
                  projectWrapper: {
                    title: {
                      textRowColor: SECONDARY_3,
                    },
                    placeHolderCards: {
                      background: {
                        rectShapeColor: SECONDARY_3,
                      },
                      left: {
                        textRowColor: PRIMARY_1,
                        info: {
                          textRowColor: PRIMARY_1,
                        }
                      },
                      right: {
                        roundShapeColor: PRIMARY_1,
                      }
                    }
                  }
                }
              },
              refreshHoursJiraModal: {
                modalContent: {
                  switchAccentColor: PRIMARY_4,
                  background: PRIMARY_13,
                  cancelar: {
                    backgroundColor: PRIMARY_4,
                    color: PRIMARY_13,
                  }
                }
              },
              projectHoursModal: {
                modalContent: {
                  background: PRIMARY_13,
                  cancelar: {
                    backgroundColor: PRIMARY_4,
                    color: PRIMARY_13,
                  },
                  trHasHour: SECONDARY_4
                }
              },
              modal: {
                modalContent: {
                  background: PRIMARY_13,
                  form: {
                    button: {
                      salvar: {
                        backgroundColor: PRIMARY_3,
                        color: PRIMARY_13
                      },
                      cancelar: {
                        backgroundColor: "transparent",
                        color: PRIMARY_3
                      },
                    }
                  }
                }
              },
              dashUser: {
                saudeProjetos: {
                  backgroundColor: PRIMARY_13,
                },
                title: {
                  color: PRIMARY_13,
                  backgroundColor: SECONDARY_9
                },
                container: {
                  content: {
                    pColor: PRIMARY_3,
                    welcomeColor: PRIMARY_9,
                    topCards: {
                      horasProjeto: {
                        backgroundColor: PRIMARY_13,
                        valorGastoColor: PRIMARY_9
                      },
                      valorGastoMes: {
                        backgroundColor: PRIMARY_13,
                        valorGastoColor: PRIMARY_9
                      },
                      progressoProjeto: {
                        backgroundColor: PRIMARY_13,
                        porcentagemColor: PRIMARY_9
                      }
                    },
                    bottomCardsTitle: {
                      pColor: PRIMARY_9,
                      clockwork: {
                        backgroundColor: PRIMARY_13,
                        borderColor: SECONDARY_11,
                        borderHoverColor: SECONDARY_4,
                        svgColor: SECONDARY_4,
                      }
                    },
                    historyTitleColor: PRIMARY_9,
                    areaHistory: {
                      backgroundColor: PRIMARY_13,
                      span0Color: PRIMARY_4,
                      span1Color: SECONDARY_5
                    }
                  },
                },
                cardAmount: {
                  graphic: {
                    backgroundColor: '#4e4b9866',
                    color: SECONDARY_5,
                    description: {
                      h6Color: SECONDARY_5,
                    }
                  },
                  recebido: {
                    backgroundColor: SECONDARY_5,
                    spanColor: PRIMARY_13,
                    backgroundHoverColor: '#4e4b9880'
                  },
                  container: {
                    color: SECONDARY_5,
                    projectCard: {
                      backgroundColor: PRIMARY_13,
                      cardTitleMobile: {
                        color: PRIMARY_3,
                      },
                      graphicDisplayColor: SECONDARY_5,
                    }
                  }
                }
              }
            }
          },
        },
        components: {
          allocationModal: {
            contentProject: {
              borderColor: '#ebebeb'
            }
          },
          cardWithJobAllocatedInProject: {
            container: {
              backgroundColor: PRIMARY_13,
              borderColor: '#aeaeb3',
              boxShadowColor: '#aeaeb340',
              selected: {
                backgroundColor: '#f8fffb',
                borderColor: '#7fae38a1',
                boxShadowColor: '#81ae3833',
              }
            },
            header: {
              svgColor: '#47474d',
              svgColorSelected: '#81ae388f'
            },
            subtitle: {
              color: '#a1a1a1'
            }
          },
          filterItem: {
            container: {
              backgroundColor: SECONDARY_5,
              borderColor: SECONDARY_5,
              color: PRIMARY_13
            }
          },
          filterSelect: {
            container: {
              background: PRIMARY_13,
              color: PRIMARY_9,
              spanColor: PRIMARY_9,
            },
            selectFilter: {
              color: 'transparent',
              background: 'transparent',
              option: {
                backgroundColor: PRIMARY_13,
                color: PRIMARY_9
              }
            }
          },
          searchStatus: {
            container: {
              select2Container: {
                webkitScrollbarBackgroundColor: PRIMARY_13,
                webkitScrollbarThumbColor: '#eeeeee',
              }
            }
          }
        }
      },
      auth: {
        components: {
          select2: {
            borderColor: '#ff0000',
            placeholderColor: PRIMARY_1,
          }
        },
        createNewPassword: {
          containerBackgroundColor: PRIMARY_13,
          containerWithIconBorderColor: PRIMARY_4,
          containerInput: {
            h1Color: PRIMARY_9,
            h3Color: PRIMARY_9,
            subtitleColor: PRIMARY_9,
            aBackgroundColor: PRIMARY_13,
            aColor: PRIMARY_9
          }
        },
        forgotPassword: {
          styleModal: {
            webkitScrollbarBackgroundColor: PRIMARY_13,
            webkitScrollbarThumbColor: '#999999',
          },
          container: {
            background: 'transparent'
          },
          button: {
            borderColor: SECONDARY_15,
            backgroundPrimaryColor: SECONDARY_15,
            backgroundSecondaryColor: 'transparent',
            primaryColor: PRIMARY_13,
            secondaryColor: SECONDARY_15,
          },
          cancelButton: {
            background: '#555555',
            hoverBackground: '#474747',
            hoverDisabledBackground: '#807E80',
            color: PRIMARY_13
          },
          submitButton: {
            color: PRIMARY_13,
            background: SECONDARY_9
          },
          linkButton: {
            color: PRIMARY_13,
            background: SECONDARY_9
          },
          component: {
            createPassword: {
              containerInputs: {
                h1Color: PRIMARY_9,
                h3Color: PRIMARY_9,
                subtitleColor: PRIMARY_9,
                aBackground: PRIMARY_13,
                aColor: PRIMARY_9
              }
            }
          }
        },
        password: {
          buttonBackground: '#5867dd'
        },
        registration: {
          organizacaoColor: '#fd397a'
        }
      },
      home: {
        components: {
          contents: {
            color: '#a191ff',
          },
          navButton: {
            backgroundColor: PRIMARY_13,
            color: PRIMARY_9,
            borderColor: PRIMARY_9,
            secondary: {
              backgroundColor: '#5e49ff',
              color: '#f0f5ff',
              backgroundHoverColor: '#4b33ff',
              colorHover: '#f0f5ff',
            }
          }
        }
      },
      jobDescription: {
        components: {
          applicationModal: {
            modalColor: PRIMARY_13
          },
          followUpForm: {},
          jobFilter: {
            container: {
              background: '#f9f9f9',
              color: '#888888',
              selectedColor: PRIMARY_4,
              selectedTechColor: SECONDARY_5,
              hoverColor: '#888888',
              hoverSelectedColor: PRIMARY_4,
              hoverSelectedTechColor: SECONDARY_5,
              svgColor: PRIMARY_4,
              svgTechColor: SECONDARY_5,
              hoverBorderColor: PRIMARY_4,
              hoverBorderTechColor: SECONDARY_5,
              containerHoverColor: PRIMARY_4,
              containerHoverTechColor: SECONDARY_5,
            },
            containerSubcategories: {
              webkitScrollbar: {
                backgroundColor: PRIMARY_13,
                thumbBackground: '#eeeeee'
              }
            }
          }
        }
      },
      policy: {
        container: {
          pColor: '#a191ff',
          strongColor: '#a191ff'
        }
      }
    },
    components: {
      button: {
        container: {
          backgroundColor: PRIMARY_13,
          borderColor: PRIMARY_9,
          color: PRIMARY_9,
          hoverBackgroundColor: PRIMARY_4,
          hoverColor: PRIMARY_13,
          hoverBorderColor: PRIMARY_13
        }
      },
      footer: {
        container: {
          pColor: '#a191ff'
        }
      },
      header: {
        menuItemcolor: PRIMARY_3

      },
      iconesSvg: {
        iconeLinkedIn: PRIMARY_13,
        hoverIconeLinkedIn: PRIMARY_4,
        iconeInstagram: PRIMARY_13,
        hoverIconeInstagram: '#38d42d',
        iconeSite: PRIMARY_13,
        hoverIconeSite: '#38d42d',
        iconeYoutube: PRIMARY_13,
        hoverIconeYoutube: '#38d42d',
        iconeAddCircle: PRIMARY_16,
        hoverIconeAddCircle: PRIMARY_3,
      }
    },
    widgets: {
      quickStatsChart: {
        dataset_1: {
          pointBackgroundColor: PRIMARY_1,
          pointBorderColor: PRIMARY_1,
          pointHoverBorderColor: PRIMARY_1,
        },
      },
      salesBarChart: {
        backgroundColor: "#f3f3fb"
      },
      orderStatisticsChart: {
        dataset_1: {
          pointBackgroundColor: PRIMARY_1,
          pointBorderColor: PRIMARY_1,
          pointHoverBorderColor: PRIMARY_1,
        },
        dataset_2: {
          pointBackgroundColor: PRIMARY_1,
          pointBorderColor: PRIMARY_1,
          pointHoverBorderColor: PRIMARY_1,
        },
        titleFontColor: PRIMARY_13
      }
    }
  }
};

export default colorConfiguration;
