import { StatusAlocacaoType } from "./statusAlocacao";

export const generateStatus = (allocatedTime, estimatedTime) => {
  if (allocatedTime === 0 && estimatedTime === 0)
    return StatusAlocacaoType.POUCO_ALOCADO;

  const percentage = (allocatedTime * 100) / estimatedTime;

  switch (true) {
    case percentage >= 110:
      return StatusAlocacaoType.SUPER_ALOCADO;
    case percentage >= 80:
      return StatusAlocacaoType.ALOCADO;
    case percentage >= 60:
      return StatusAlocacaoType.PARCIALMENTE_ALOCADO;
    default:
      return StatusAlocacaoType.POUCO_ALOCADO;
  }
};
