import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { generateDate } from "../pages/admin/pages/Allocation/utils";

export const allocationTypes = {
  Month: "Month",
  Year: "Year"
};

const date = generateDate();
const INITIAL_STATE = {
  month: date.month,
  year: date.year
}

export const allocationReducer = persistReducer(
  { storage, key: "allocation" },
  (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case allocationTypes.Month: {
        return { ...state, month: action.payload };
      }
      case allocationTypes.Year: {
        return { ...state, year: action.payload };
      }
      default:
        return state;
    }
  }
);

export const allocationActions = {
  setMonth: month => ({ type: allocationTypes.Month, payload: month }),
  setYear: year => ({ type: allocationTypes.Year, payload: year })
};
