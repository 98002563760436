/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/AdminPage`).
 */

import React, { Suspense } from "react";
import AnimateLoading from "../partials/layout/AnimateLoading";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import LogoutPage from "../pages/auth/Logout";
import { LayoutContextProvider } from "../../_metronic";
import Layout from "../../_metronic/layout/Layout";
import * as routerHelpers from "../router/RouterHelpers";
import AuthPage from "../pages/auth/AuthPage";
import PrivacyPolicyPage from "../pages/policy/PrivacyPolicyPage";
import CreateNewPassword from "../pages/auth/CreateNewPassword";
import ErrorPage from "../pages/errors/ErrorPage";
const AdminRoutesComponent = React.lazy(() =>
  import("../pages/admin/AdminPage")
);

export const Routes = withRouter(({ history }) => {
  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);
  const { isAuthorized, menuConfig, userLastLocation } = useSelector(
    ({ auth, builder: { menuConfig } }) => ({
      menuConfig,
      isAuthorized: auth.user != null,
      userLastLocation: routerHelpers.getLastLocation()
    }),
    shallowEqual
  );

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <Switch>
        <Route path="/auth" component={AuthPage} />
        <Route path="/auth/login" component={AuthPage} />
        <Route path="/logout" component={LogoutPage} />
        <Route path="/privacy-policy" component={PrivacyPolicyPage} />
        <Route path="/cadastrar-senha" component={CreateNewPassword} />
        <Route path="/admin">
          <Layout>
            <Suspense fallback={<AnimateLoading/>}>
              <AdminRoutesComponent userLastLocation={userLastLocation} />
            </Suspense>
          </Layout>
        </Route>
        {!isAuthorized && <Redirect to="/auth/login" />}

        <Redirect from="/" to="/admin/dashboard" />

        {/* ERROR */}
        <Route path="*" component={ErrorPage} />
        <Route path="404" component={ErrorPage} />
      </Switch>
    </LayoutContextProvider>
  );
});
