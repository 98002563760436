import reactSelect from "react-select";
import styled from "styled-components";
import color from '../../../../../../../../../../colors';

export const CustomSelect = styled(reactSelect)`
  .SelectUserCard__option,
  .SelectUserCard__value-container,
  .SelectUserCard__indicators {
    font-size: 12px;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 12px 10px;
  color: #fff;
  font-size: 13px;
  border-top: 1px solid ${color.app.pages.admin.pages.allocation.components.projectCardAllocation.projectCard.levelsCard.userCard.container};

  button {
    appearance: none;
    border: none;
    width: fit-content;
    padding: 0 8px;

    background: ${color.app.pages.admin.pages.allocation.components.projectCardAllocation.projectCard.levelsCard.userCard.container};
    border-radius: 4px;

    svg {
      color: #fff;
    }
  }

  .inputs {
    display: flex;
    gap: 8px;
    width: 100%;
    height: 50px;
    align-items: flex-end;

    select,
    input {
      font-weight: 400;
      font-size: 12px;
      padding: 4px 8px;
      border: 0.5px solid ${color.app.pages.admin.pages.allocation.components.projectCardAllocation.projectCard.levelsCard.userCard.input};

      outline: none;
    }

    .inputContainer {
      margin: 0px;
      display: flex;
      flex-direction: column;
      height: 53px;
    }

    label {
      color: black;
      margin: 0px;
      padding: 0px;
      font-size: 10px;
    }

    .hoursInput {
      width: 50px;
    }

    .select2-card {
      color: initial;
      width: 100%;
    }

    input {
      text-align: center;
      flex: 1;
      width: 4ch;
      border-radius: 5px;
      color: rgb(128, 128, 128);
    }
  }
`;
