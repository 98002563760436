import { USER_TYPES } from "../../app/enums/UsersTypes";
import store from "../../app/store/store";
import { isCollaborator } from "../../app/utils/isCollaborator";

const DASHBOARD = {
      title: "Dashboard",
      root: true,
      icon: "flaticon2-architecture-and-city",
      page: "admin/dashboard",
      translate: "MENU.DASHBOARD",
      bullet: "dot"
    }

const USERS = {
  title: "Usuários",
  root: true,
  icon: "flaticon2-user-1",
  bullet: "dot",
  page: "admin/usuarios"
};

const PROJECTS = {
  title: "Projetos",
    root: true,
    icon: "fas fa-laptop-code",
    bullet: "dot",
    submenu: [
      { title: "Gerenciar Projetos", page: "admin/projetos" },
      { title: "Alocação Mensal", page: "admin/alocacao" },
      { title: "Gerenciar Níveis", page: "admin/jobDescriptionLevel", roles: [USER_TYPES.ADMINISTRATOR] },
      { title: "Gerenciar Parâmetros", page: "admin/parameter", roles: [USER_TYPES.ADMINISTRATOR] }
  ]
}

const COMPANIES = {
  title: "Empresas",
  root: true,
  icon: "flaticon2-group",
  bullet: "dot",
  page: "admin/empresas"
}

const CLOCKWORK = {
  title: "Lançamento de Horas",
  root: true,
  icon: "flaticon2-chronometer",
  bullet: "dot",
  page: "admin/clockwork",
}

const REIMBURSEMENT = {
  title: "Reembolso de despesas",
  root: true,
  icon: "flaticon-piggy-bank",
  bullet: "dot",
  page: "admin/reembolsos"
}

const REPORT = {
  title: "Relatórios",
  root: true,
  icon: "flaticon-analytics",
  bullet: "dot",
  page: "admin/relatorios"
}

export default () => {
  const { auth } = store.getState();
  const menu = {
    header: {
      self: {},
      items: []
    },
    aside: {
      self: {},
      items: []
    }
  }

  if(auth?.user?.profile === USER_TYPES.ADMINISTRATOR) {
    menu.aside.items.push(DASHBOARD, COMPANIES, PROJECTS, USERS, CLOCKWORK, REIMBURSEMENT, REPORT)
  }
  if(auth?.user?.profile === USER_TYPES.MANAGER
    || auth?.user?.profile === USER_TYPES.COORDINATOR
    || auth?.user?.profile === USER_TYPES.DIRECTOR) {
    menu.aside.items.push(DASHBOARD, PROJECTS, CLOCKWORK, REIMBURSEMENT, REPORT)
  }
  if(auth?.user?.profile === USER_TYPES.EMPLOYEE) {
    menu.aside.items.push(DASHBOARD, CLOCKWORK, REIMBURSEMENT)
  }
  if(auth?.user?.profile === USER_TYPES.AUDITOR) {
    menu.aside.items.push(DASHBOARD, REIMBURSEMENT)
  }

  menu.aside.items = menu?.aside?.items?.filter(item => {
    if(!item.submenu || !auth?.user?.profile)
      return true;

    if(item.submenu.some(submenu => !submenu.roles || submenu.roles?.includes(auth?.user?.profile)))
      return true;

    return false;
  });

  menu.aside.items = menu?.aside?.items?.map(item => {
    if(item.submenu) {
      item.submenu = item.submenu.filter(submenu => !submenu.roles || submenu.roles?.includes(auth?.user?.profile));
      if(item.submenu.length === 1)
        delete item.submenu
    }
    return item;
  });

  if (isCollaborator()) {
    menu.aside.items = menu?.aside?.items?.filter(item => item !== REPORT);
    menu.aside.items = menu?.aside?.items?.filter(item => item !== DASHBOARD);
  }
  

  return menu;
};
