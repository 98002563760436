import {VizzionApiService} from "./VizzionApiService";

export class UserService extends VizzionApiService {
  _endpoint = "/user";

  constructor() {
    super({});
  }

  async create(data) {
    return this.makeHttpRequest({
      url: this._endpoint,
      method: 'POST',
      data
    });
  }

  async getById(id) {
    return this.makeHttpRequest({
        url: `${this._endpoint}/${id}`,
        method: 'GET'
    })
  }

  async getPaged(filter) {
    return this.makeHttpRequest({
      url: this._endpoint,
      method: 'GET',
      params: filter,
      paramsSerializer: params => {
        return Object.entries(params)
          .map(([key, value]) => `${key}=${value}`)
          .join('&');
      }
    });
  }

  async delete(id) {
    return this.makeHttpRequest({
        url: `${this._endpoint}/${id}`,
        method: 'DELETE'
    });
  }

  async update(id, data) {
    return this.makeHttpRequest({
        url: `${this._endpoint}/${id}`,
        method: 'PUT',
        data
    });
  }
}
