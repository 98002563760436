import styled from 'styled-components';

import colors from '../../../colors'

const configColor = colors.app.components.button;

export const Container = styled.button`
 display: flex;
 height: 56px;
 width: 100%;
 background-color: ${configColor.container.backgroundColor};
 border: 1px solid ${configColor.container.borderColor};
 color: ${configColor.container.color};
 font-family: "Montserrat", sans-serif;
 font-weight: 600;
 font-size: 18px;
 font-weight: 600;
 align-items: center;
 justify-content: center;
 margin-bottom: ${({marginBottom}) => marginBottom ? marginBottom : 0};

 svg {
	transform: rotate(90deg);
 }

 :hover {
   transition: ${({isLoading}) => isLoading ? 0 :  '0.3s'};
   opacity: ${({isLoading}) => isLoading ? 1 :  '0.8'};
   border: 1px solid ${configColor.container.hoverBorderColor};
   border: ${({isLoading}) => isLoading && 0};
   color: ${configColor.container.hoverColor};
   background: ${configColor.container.hoverBackgroundColor};
  }
`;
