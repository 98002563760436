import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as builder from "../../ducks/builder";

class Footer extends React.Component {
  render() {
    const todayYear = new Date().getFullYear();
    return (
      <div
        className={`kt-footer ${this.props.footerClasses} kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop`}
        id="kt_footer"
        style={{backgroundColor:"#E5E5E5"}}
      >
        <div className={`kt-container ${this.props.footerContainerClasses}`}>
          <div className="kt-footer__copyright">
            {todayYear.toString()}&nbsp;&copy;&nbsp;
            <Link to="/" className="kt-link">VIZZION</Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  footerClasses: builder.selectors.getClasses(store, {
    path: "footer",
    toString: true
  }),
  footerContainerClasses: builder.selectors.getClasses(store, {
    path: "footer_container",
    toString: true
  })
});

export default connect(mapStateToProps)(Footer);
