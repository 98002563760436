import React, { useState } from "react";
import Loading from "../../home/components/Loading";
import IsValidEmail from "../../../utils/validators/IsValidEmail";
import { VizzionApiService, ErrorCode } from "../../../services/VizzionApiService";
import Swal from "sweetalert2";
import { Modal, Button } from "react-bootstrap";
import { Container, StyledInput, ButtonForm, StyledModal } from "./style";
import { CreatePassword } from "./component/CreatePassword";
import { Typography } from "@material-ui/core";

const ActivateUserModal = ({ show, onHide, onConfirm }) => {
  return(
    <StyledModal
      show={show}
      onHide={onHide}
      size="lg"
      centered
      backdrop="static"
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Modal.Body>
        <Typography variant="h4" component="h2">
          Ops!
        </Typography>
        <Typography variant="body1" component="p">
          Parece que seu usuário ainda não foi ativado.
          <br />
          Não se preocupe, vamos reenviar um e-mail de confirmação pra você :)
          <br />
          A propósito, desconsidere e-mails de ativação antigos, clique no link do último e-mail que enviamos a você!
        </Typography>
        <Button
          type="submit"
          variant="primary"
          size="md"
          onClick={onConfirm}
        >
          <span className="text-light">Entendi</span>
        </Button>
      </Modal.Body>
    </StyledModal>
  )
};

const ForgotPassword = ({ title }) => {
  const api = new VizzionApiService();
  const [openForgotModal, setOpenForgotModal] = useState(false);
  const [openActivateUserModal, setOpenActivateUserModal] = useState(false);

  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");

  const [emailSent, setEmailSent] = useState(false);
  const [codeVerified, setCodeVerified] = useState(false);

  const [disabledButton, setDisabledButton] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const reloadForm = () => {
    setEmail("");
    setVerificationCode("");
    setEmailSent(false);
    setCodeVerified(false);
    setError("");
    setDisabledButton(false);
  };

  const handleActivateUserModalConfirm = async () => {
    setIsLoading(true);
    try {
      await api.makeHttpRequest({
        url: `/user/resend-mail/${email}`,
        method: "PUT",
      });
      Swal.fire(
        "Sucesso",
        "O email de confirmação foi enviado.",
        "success"
      );
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Tivemos problemas ao enviar o email de recuperação. Por favor, tente novamente.",
        "error"
      );
    }

    setIsLoading(false);
    setOpenActivateUserModal(false);
    reloadForm();
  }

  const handleEmailChange = e => {
    const value = e.target.value;
    setEmail(value);

    if (value === "") {
      setError("Preenchimento obrigatório");
      setDisabledButton(false);
    } else if (!IsValidEmail(value)) {
      setError("Este email é invalido!");
      setDisabledButton(false);
    } else {
      setError("");
    }
  };

  const handleCodeChange = e => {
    const value = e.target.value;
    setVerificationCode(value);

    if (value === "") {
      setError("Preenchimento obrigatório");
      setDisabledButton(false);
    } else if (isNaN(Number(value)) || value.length !== 6) {
      setError("Código inválido");
      setDisabledButton(false);
    } else {
      setError("");
    }
  };

  const handleEmailSend = async () => {
    if (error !== "") return;

    setDisabledButton(true);
    setIsLoading(true);

    try {
      await api.makeHttpRequest({
        url: "/user/recuperar-senha",
        method: "POST",
        data: {
          email
        }
      });

      setEmailSent(true);
      Swal.fire(
        "Sucesso",
        "Se existir uma conta com esse e-mail, um código de verificação foi enviado ao e-mail.",
        "success"
      );
    } catch (e) {
      const errorCode = e.response?.data?.error;

      if (errorCode) {
        switch(errorCode) {
          case ErrorCode.UserErrorInactiveUser:
            {
              setOpenForgotModal(false);
              setOpenActivateUserModal(true);
            }
            break;
          default:
            break;
        }
      } else {
        Swal.fire(
          "Erro!",
          e?.response?.data
            ? e.response.data
            : "Tivemos problemas ao enviar o email de recuperação. Por favor, tente novamente.",
          "error"
        );
      }

    }

    setDisabledButton(false);
    setIsLoading(false);
  };

  const handleCodeVerification = async () => {
    if (error !== "") return;

    setDisabledButton(true);
    setIsLoading(true);

    try {
      await api.makeHttpRequest({
        url: "/user/confirm-token",
        method: "POST",
        data: {
          email,
          token: verificationCode
        }
      });

      setCodeVerified(true);
      Swal.fire(
        "Sucesso",
        "Código de verificação validado com sucesso.",
        "success"
      );
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Tivemos problemas ao validar o seu código de verificação. Por favor, tente novamente.",
        "error"
      );
    }

    setDisabledButton(false);
    setIsLoading(false);
  };

  return (
    <>

      <li
        id="kt_login_signin_submit"
        type="button"
        onClick={() => setOpenForgotModal(true)}
        className="justify-content-start forgot-password"
      >
        { title }
      </li>
      <Loading isLoading={isLoading} />
      <StyledModal
        show={openForgotModal}
        onHide={() => setOpenForgotModal(false)}
        size="lg"
        centered
        backdrop="static"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Modal.Body>
          {!emailSent ? (
            <Container>
              <h1 className="forgot-title">Esqueceu sua senha?</h1>
              <p className="forgot-subtitle">
                Insira seu email para iniciar a recuperação da senha:
              </p>

              <StyledInput
                placeholder="Insira seu email"
                value={email}
                error={error !== "" ? true : false}
                onChange={handleEmailChange}
                helperText={error !== "" && error}
              />

              <ButtonForm>
                <Button
                  type="button"
                  variant="secondary"
                  size="lg"
                  disabled={disabledButton}
                  onClick={() => setOpenForgotModal(false)}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  size="lg"
                  disabled={disabledButton}
                  onClick={handleEmailSend}
                >
                  <span className="text-light">Enviar</span>
                </Button>
              </ButtonForm>
            </Container>
          ) : !codeVerified ? (
            <Container>
              <h1 className="forgot-title">Código de verificação</h1>
              <p className="forgot-subtitle">
                Insira o codigo de 6 digitos enviado ao seu e-mail:
              </p>
              <StyledInput
                placeholder="Insira o código de verificação"
                value={verificationCode}
                error={error !== "" ? true : false}
                onChange={handleCodeChange}
                helperText={error !== "" && error}
              />

              <ButtonForm>
                <Button
                  type="button"
                  variant="secondary"
                  size="lg"
                  disabled={disabledButton}
                  onClick={() => setOpenForgotModal(false)}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  size="lg"
                  disabled={disabledButton}
                  onClick={handleCodeVerification}
                >
                  <span className="text-light">Enviar</span>
                </Button>
              </ButtonForm>
            </Container>
          ) : (
            <CreatePassword
              email={email}
              close={() => setOpenForgotModal(false)}
              resetAll={reloadForm}
            />
          )}


        </Modal.Body>
      </StyledModal>
      <ActivateUserModal
        show={openActivateUserModal}
        onHide={() => handleActivateUserModalConfirm()}
        onConfirm={() => handleActivateUserModalConfirm()}
      />
    </>
  );
};

export default ForgotPassword;
