import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "./ducks/auth.duck";
import { calendarioReducer} from "./calendario";
import { desafioEspecialReducer} from "./desafioEspecial";
import { metronic } from "../../_metronic";
import { insightReducer } from "./insight";
import { allocationReducer } from "./alocacao";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
  calendario: calendarioReducer,
  desafioEspecial: desafioEspecialReducer,
  insight: insightReducer,
  allocation: allocationReducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
