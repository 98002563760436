/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { useState, useEffect, useCallback } from "react";
import ReactGA from "react-ga";
import { Provider } from "react-redux";
import { Redirect, Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { PersistGate } from "redux-persist/integration/react";
import { LastLocationProvider } from "react-router-last-location";
import { Routes } from "./app/router/Routes";
import { getUserByToken } from "./app/crud/auth.crud";
import { I18nProvider, LayoutSplashScreen } from "./_metronic";
import bsCustomFileInput from "bs-custom-file-input";
import { QueryClientProvider, QueryClient } from "react-query"

bsCustomFileInput.init();

const { REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID } = process.env;
if (REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
  ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
}

const history = createBrowserHistory();

function buildPath(location) {
  return `${location.pathname}${location.hash}`;
}

history.listen(location => {
  if (!REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) return;

  const path = buildPath(location);
  ReactGA.set({ page: path }); // Update the user's current page
  ReactGA.pageview(path); // Record a pageview for the given page
});

export default function App({ store, persistor, basename }) {
  const [isLoading, setIsLoading] = useState(true);

  const refreshToken = useCallback(async token => {
    if (token) {
      await getUserByToken().catch(() => {
        return <Redirect to="/auth/login" />
      })
    }
    setIsLoading(false);
  }, []);

  let auth;
  let token;
  if (window.localStorage.getItem("persist:auth")) {
    auth = JSON.parse(window.localStorage.getItem("persist:auth"))?.authData;
    if (auth && JSON.parse(auth)) {
      token = JSON.parse(auth)?.token;
    }
  }

  useEffect(() => {
    if (REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
      const path = buildPath(history.location);
      ReactGA.set({ page: path });
      ReactGA.pageview(path);
    }
    refreshToken(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const queryClient = new QueryClient()

  return (
    /* Provide Redux store */
      <Provider store={store} loading={<LayoutSplashScreen />}>
        <PersistGate persistor={persistor}>
          <Router basename={basename} history={history}>
            <LastLocationProvider>
              <QueryClientProvider client={queryClient}>
                <I18nProvider>{!isLoading && <Routes />}</I18nProvider>
              </QueryClientProvider>
            </LastLocationProvider>
          </Router>
        </PersistGate>
      </Provider>
  );
}
