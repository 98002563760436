import { Backdrop, Button, Fade, makeStyles, Menu, MenuItem, Modal } from '@material-ui/core';
import React, { useState } from 'react';
import { RiLoginBoxLine, RiLogoutBoxRLine } from 'react-icons/ri';
import { Link, useHistory } from 'react-router-dom';

import UserProfile from '../../../app/partials/layout/UserProfile';
import colors from '../../../colors';
import iconClose from '../../assets/icons/icons_figma/icon_close.svg';
import iconMenu from '../../assets/icons/icons_figma/icon_menu.svg';
import logo from '../../assets/logo/logo.png';
import WindowSize from '../../constants/WindowSize';
import store from '../../store/store';
import useGetWindowWidth from '../../utils/useGetWindowWidth';

const configColor = colors.app.components.header;

const Header = ({ home }) => {
  const { auth } = store.getState();
  const [showModal, setShowModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const width = useGetWindowWidth();
  const history = useHistory();

  const useStyles = makeStyles({
    root: {
      borderRadius: 3,
      minWidth: 0,
      padding: 4
    },
    modal: {
      display: "block",
      position: "absolute",
      zIndex: 1,
      marginTop: width > WindowSize.MD ? 114 : 82,
      width: width > WindowSize.MD ? "30%" : "80%"
    },
    menu: {
      marginLeft: 26,
      marginTop: 10
    },
    menuItem: {
      width: 210,
      marginRight: 8,
      marginLeft: 8,
      color: configColor.menuItemcolor,
      fontFamily: "Montserrat, sans-serif"
    }
  });

  const classes = useStyles();

  const handleClick = event => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div id="header" className="container-header">
      <Link to="/">
        <img src={logo} className="logo-header" alt="Logo Vizzion" />
      </Link>

      {width >= WindowSize.LG && (
        <ul>
          {auth.user ? (
            <li>
              <UserProfile showBadge={true} />
            </li>
          ) : (
            <li>
              <Link to="/auth">
                <div className="login-btn">
                  <span>
                    Login{" "}
                    {width > 1180 ? "/ Acesso interno" : <RiLoginBoxLine />}
                  </span>
                </div>
              </Link>
            </li>
          )}
        </ul>
      )}

      {/* Remover a validação da linha 77 após implementar a Área do Cliente */}
      {width < WindowSize.LG && (
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={event =>
            width >= WindowSize.MD
              ? handleClick(event)
              : setShowModal(!showModal)
          }
          className={classes.root}
        >
          <img src={iconMenu} alt="Ícone Menu" />
        </Button>
      )}

      <Menu
        className={classes.menu}
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={() => history.push("/auth")}
        >
          Acesso interno
        </MenuItem>
      </Menu>

      <Modal
        closeAfterTransition
        BackdropProps={{
          style: { marginTop: width > WindowSize.MD ? 114 : 82 },
          timeout: 500
        }}
        BackdropComponent={Backdrop}
        className={classes.modal}
        onClose={() => setShowModal(false)}
        open={showModal}
      >
        <Fade in={showModal}>
          <div className="container-content-modal">
            <ul>
              <li>
                <Button
                  onClick={() => setShowModal(false)}
                  className={classes.root}
                >
                  <img src={iconClose} alt="Ícone fechar menu" />
                </Button>
              </li>
              {auth.user ? (
                  <li className="header-logout">
                    <Link to="/logout">
                      <span>
                        Sair <RiLogoutBoxRLine color="E60023" />
                      </span>
                    </Link>
                  </li>
                ) : (
                  <>
                    <li>
                      <Link to="/auth">Acesso interno</Link>
                    </li>
                    <li className="header-logout">
                      <Link to="/logout">
                        <span>
                          Sair <RiLogoutBoxRLine color="E60023" />
                        </span>
                      </Link>
                    </li>
                  </>
                )}
            </ul>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default Header;
