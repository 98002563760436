import {VizzionApiService} from "./VizzionApiService";

export class LevelService extends VizzionApiService {
  _endpoint= "/level";

  constructor() {
    super({});
  }

  async create(data) {
    return await this.makeHttpRequest({
      method: "POST",
      url: `${this._endpoint}/salvar`,
      data,
    });
  }

  async update(id, data) {
    return await this.makeHttpRequest({
      method: "PUT",
      url: `${this._endpoint}/atualizar/${id}`,
      data,
    });
  }

  async delete(id) {
    return await this.makeHttpRequest({
      method: "DELETE",
      url: `${this._endpoint}/${id}`,
    });
  }

  async getById(id) {
    return await this.makeHttpRequest({
      method: "GET",
      url: `${this._endpoint}/${id}`,
    });
  }

  async levels() {
    return await this.makeHttpRequest({
      method: "GET",
      url: `${this._endpoint}/levels`,
    });
  }
}
