import { TextField } from '@material-ui/core';
import styled from 'styled-components';

import colors from '../../../../../../colors';

const colorConfig = colors.app.pages.auth.forgotPassword.component.createPassword;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 0;
  max-width: 450px;

  .close-icon {
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
  }
`;

export const ContainerWithIcon = styled.div`
  width: 100%;
`;

export const ContainerInputs = styled.div`
  > h1 {
    color: ${colorConfig.containerInputs.h1Color};
    margin-bottom: 20px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    text-align: center;
  }

  > h3 {
    color: ${colorConfig.containerInputs.h3Color};
    text-align: center;
    margin-bottom: 20px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
  }

  .subtitle {
    text-align: center;
    color: ${colorConfig.containerInputs.subtitleColor};
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;

    margin-bottom: 5px;
  }

  form {
    display: flex;
    flex-direction: column;

    & > .MuiFormControl-root.MuiTextField-root:first-child {
      margin: 5px 0 15px;
    }
  }

  a {
    display: flex;
    flex: 1;
    padding: 15px 0;
    align-items: center;
    justify-content: center;
    background-color: ${colorConfig.containerInputs.aBackground};
    border: none;
    color: ${colorConfig.containerInputs.aColor};
    border-radius: 12px;
    font-size: 18px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;

    :hover {
      transition: opacity 0.4s;
      opacity: 0.8;
      text-decoration: underline !important;
    }
  }
`;

export const StyledInput = styled(TextField)`
  margin-bottom: 5px;

  input {
    font-size: 18px;
    padding: 10px 5px;
  }
`;
