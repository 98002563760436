import React from 'react';

import {Container} from './styles';
import logoDevariaLight from '../../../../assets/logo/logo.png'

import useGetWindowWidth from '../../../../utils/useGetWindowWidth';
import windowSize from '../../../../constants/WindowSize';

const Header = () => {
  const width = useGetWindowWidth();

  return (
    <Container width={width}>
    {/* // className='row align-items-center justify-content-between'> */}
     {width <  windowSize.SM &&
     <img alt="logo Devaria"
     src={logoDevariaLight}
     style={{width: 120}} />}
   </Container>
  );
};

export default Header;
