import React, { useCallback, useState } from "react";
import { Form, Formik } from "formik";
import Swal from "sweetalert2";
import WindowSize from "../../../../../constants/WindowSize";
import { isValidNewPassword } from "../../../../../utils/validators/isValidYup";
import { VizzionApiService } from "../../../../../services/VizzionApiService";
import useGetWindowWidth from "../../../../../utils/useGetWindowWidth";
import Button from "../../../../../components/Button";
import { RiCloseLine, RiLockPasswordLine } from "react-icons/ri";
import { VisibilityOutlined, VisibilityOffOutlined } from "@material-ui/icons";
import { IconButton, InputAdornment } from "@material-ui/core";
import {
  Container,
  ContainerInputs,
  ContainerWithIcon,
  StyledInput
} from "./styles";

export const CreatePassword = ({ email, close, resetAll }) => {
  const [newPswVisibility, setNewPswVisibility] = useState(false);
  const [confirmPswVisibility, setConfirmPswVisibility] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const width = useGetWindowWidth();

  const handleSubmit = useCallback(
    async (values, resetForm) => {
      try {
        const api = new VizzionApiService();
        setIsLoading(true);
        await api.makeHttpRequest({
          url: "/requisicaotemporaria",
          method: "POST",
          data: { email, password: values.password }
        });

        close();
        resetAll();
        resetForm();

        Swal.fire(
          "Sucesso",
          "Senha alterada com sucesso.",
          "success"
        );
      } catch (e) {
        Swal.fire(
          "Erro!",
          e?.response?.data
            ? e.response.data
            : "Tivemos problemas na criação da sua nova senha. Por favor, tente novamente.",
          "error"
        );
      }

      setIsLoading(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <>
      <Container width={width}>
        <div className="close-icon" onClick={close}>
          <RiCloseLine size="30px" />
        </div>
        <ContainerWithIcon width={width}>
          <ContainerInputs>
            {width >= WindowSize.SM ? (
              <h3>Definir senha</h3>
            ) : (
              <h1>Definir senha</h1>
            )}
            <p className="subtitle">Crie uma nova senha para sua conta</p>
            <Formik
              initialValues={{ password: "", confirmePassword: "" }}
              validationSchema={isValidNewPassword}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                handleSubmit(values, resetForm);
                setSubmitting(false);
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur }) => (
                <Form>
                  <StyledInput
                    placeholder="Senha"
                    type={newPswVisibility ? "text" : "password"}
                    name="password"
                    value={values.password}
                    error={touched.password && errors.password ? true : false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={
                      touched.password && errors.password ? errors.password : ""
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <RiLockPasswordLine size="18px" opacity=".70" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Alterar visibilidade da senha"
                            onClick={() =>
                              setNewPswVisibility(!newPswVisibility)
                            }
                            onMouseDown={e => e.preventDefault()}
                            edge="end"
                          >
                            {newPswVisibility ? (
                              <VisibilityOffOutlined />
                            ) : (
                              <VisibilityOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />

                  <StyledInput
                    placeholder="Confirme sua senha"
                    type={confirmPswVisibility ? "text" : "password"}
                    name="confirmePassword"
                    value={values.confirmePassword}
                    error={
                      touched.confirmePassword && errors.confirmePassword
                        ? true
                        : false
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={
                      touched.confirmePassword && errors.confirmePassword
                        ? errors.confirmePassword
                        : ""
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <RiLockPasswordLine size="18px" opacity=".70" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Alterar visibilidade da confirmação da senha"
                            onClick={() =>
                              setConfirmPswVisibility(!confirmPswVisibility)
                            }
                            onMouseDown={e => e.preventDefault()}
                            edge="end"
                          >
                            {confirmPswVisibility ? (
                              <VisibilityOffOutlined />
                            ) : (
                              <VisibilityOutlined />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />

                  <Button
                    text="Cadastrar"
                    disabled={
                      (!values.password ||
                        !values.confirmePassword) &&
                      true
                    }
                    opacity={
                      (!values.password ||
                        !values.confirmePassword) &&
                      0.5
                    }
                    cursor={
                      (!values.password ||
                        !values.confirmePassword) &&
                      "not-allowed"
                    }
                    loading={isLoading}
                    type="submit"
                  />
                </Form>
              )}
            </Formik>
          </ContainerInputs>
        </ContainerWithIcon>
      </Container>
    </>
  );
};
