import './Style.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Checkbox, FormControlLabel, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import colors from '../../../colors';
import { register } from '../../crud/auth.crud';
import { VizzionApiService } from '../../services/VizzionApiService';
import * as auth from '../../store/ducks/auth.duck';
import IsExistentEmailOrLogin from '../../utils/validators/IsExitentEmail';
import verifyCpfCnpj from '../../utils/validators/IsValidCpfCnpj';
import { IsValidPassword } from '../../utils/validators/IsValidPassword';
import Loading from '../home/components/Loading';
import { SelectSearchComponent } from './Components/Select2';
import Switch from './Components/Switch';

const colorConfig = colors.app.pages.auth.registration;

function Registration(props) {
  const api = new VizzionApiService();
  const { intl } = props;

  const [typeRegistration, setTypeRegistration] = useState("");
  const [practiceArea, setPracticeArea] = useState("");
  const [practiceAreaId, setPracticeAreaId] = useState(-1);
  const [practiceAreaData, setPracticeAreaData] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [verifyEmailExists, setVerifyEmailExists] = useState(false);
  const [resetInput, setResetInput] = useState(false);
  const [resetSwitch, setResetSwitch] = useState(true);
  const [socialData, setSocialData] = useState("");
  const [email, setEmail] = useState("");
  const [sucess, setSucess] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [requiredOrganizacao, setRequiredOrganizacao] = useState({
    status: false,
    color: colorConfig.organizacaoColor
  });
  const [newOption, setNewOption] = useState(false);

  const setResponseSwitch = type => {
    setTypeRegistration(type);
    setPracticeAreaId(-1);
    setOrganizationName("");
  };
  const getPracticeAreaData = async () => {
    const practiceAreaDataApi = await api.makeHttpRequest({
      url: "/areaAtuacao"
    });
    setPracticeAreaData(practiceAreaDataApi);
  };

  const verifyEmail = async email => {
    const emailExists = await IsExistentEmailOrLogin("", email);
    setVerifyEmailExists(emailExists);
    setEmail(email);
  };

  useEffect(() => {
    getPracticeAreaData(); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.location.state) {
      setSocialData(props.location.state.socialData);
    }
  }, [props.location]);

  window.setPageTitle("Cadastro");

  return (
    <div className="kt-login__body">
      <Loading isLoading={isLoading} />
      <div
        className={
          typeRegistration
            ? "kt-login__form"
            : "kt-login__form container-switch"
        }
      >
        <div className="kt-login__title mb-4">
          {typeRegistration ? (
            <h3>
              <FormattedMessage id="AUTH.REGISTER.TITLE" />
            </h3>
          ) : (
            <h1>
              <FormattedMessage id="AUTH.REGISTER.TITLE" />
            </h1>
          )}
        </div>

        <Formik
          initialValues={{
            email: null,
            fullname: null,
            password: "",
            acceptTerms: true,
            confirmPassword: "",
            practiceArea: "",
            cpfCnpj: "",
            cpfCnpjOrg: "",
            describePracticeArea: "",
            pessoaFisica: false
          }}
          validate={values => {
            const errors = {};

            if (!values.email) {
              errors.email = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            } else {
              if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_FIELD"
                });
              } else {
                if (email !== values.email) {
                  verifyEmail(values.email);
                }
              }
            }

            if (verifyEmailExists) {
              errors.email = "Esse e-mail já é utilizado por outra conta.";
            }

            if (!values.fullname) {
              errors.fullname = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            }

            if (!values.password) {
              errors.password = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            } else if (IsValidPassword(values.password) !== true) {
              errors.password = "Senha não possui os requisitos mínimos:";
              IsValidPassword(values.password).ERROS_FOUND.map(item => {
                errors.password +=
                  " /" +
                  intl.formatMessage({
                    id: "AUTH.VALIDATION." + item
                  });
                return errors.password;
              });
            }

            if (!values.confirmPassword) {
              errors.confirmPassword = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            } else if (values.password !== values.confirmPassword) {
              errors.confirmPassword =
                "Senha e confirmação de senha não conferem.";
            }

            if (
              !practiceArea &&
              typeRegistration === "Organizador" &&
              !practiceAreaId
            ) {
              errors.practiceArea = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            }

            if (
              verifyCpfCnpj(values.cpfCnpj).error &&
              practiceAreaId &&
              values.cpfCnpj
            ) {
              errors.cpfCnpj = verifyCpfCnpj(values.cpfCnpj).error;
            }

            if (
              verifyCpfCnpj(values.cpfCnpjOrg).error &&
              !practiceAreaId &&
              values.cpfCnpjOrg
            ) {
              errors.cpfCnpjOrg = verifyCpfCnpj(values.cpfCnpjOrg).error;
            }

            if (
              !values.describePracticeArea &&
              practiceArea.nome === "Outros"
            ) {
              errors.describePracticeArea = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            }

            // if (!values.acceptTerms) {
            //   errors.acceptTerms = "Aceite os termos para continuar!";
            // }

            return errors;
          }}
          onSubmit={async (values, { setStatus, setSubmitting, resetForm }) => {
            if (
              !organizationName &&
              typeRegistration === "Organizador" &&
              !values.pessoaFisica
            ) {
              setRequiredOrganizacao({ status: true, color: colorConfig.organizacaoColor });
              return;
            }
            setRequiredOrganizacao({ status: false, color: colorConfig.organizacaoColor });
            setIsLoading(true);
            if (typeRegistration === "Participante") {
              let response;
              try {
                response = await register(
                  values.email,
                  values.email,
                  values.fullname,
                  values.cpfCnpj.replace(/[^\d]+/g, ""),
                  values.password,
                  null,
                  null,
                  socialData && socialData.graphDomain ? socialData.email : "",
                  socialData && !socialData.graphDomain ? socialData.email : ""
                );
                if (response) {
                  setIsLoading(false);
                  setSubmitting(false);
                  resetForm({});
                  props.register(response);
                  setResetInput(true);
                  setSucess(true);
                  setStatus(
                    intl.formatMessage({
                      id: "AUTH.GENERAL.REGISTER_SUCESS"
                    })
                  );
                }
                window.scrollTo(0, 0);
              } catch (error) {
                setIsLoading(false);
                setSucess(false);
                setSubmitting(false);
                if (error.response && error.response.data) {
                  setStatus(error.response.data);
                } else {
                  setStatus(
                    intl.formatMessage({
                      id: "AUTH.VALIDATION.INVALID_REGISTER"
                    })
                  );
                }
                window.scrollTo(0, 0);
              }
            } else {
              const organization = {
                nome: values.pessoaFisica ? values.fullname : organizationName,
                cnpj: values.cpfCnpjOrg.replace(/[^\d]+/g, ""),
                idAreaAtuacao: values.pessoaFisica
                  ? practiceAreaData.find(element => element.nome === "Outros")
                      .id
                  : practiceAreaId
                  ? practiceAreaId
                  : practiceArea.id,
                nomeAreaAtuacao: values.pessoaFisica
                  ? "Outros - Pessoa Física"
                  : practiceArea.nome === "Outros"
                  ? practiceArea.nome + " - " + values.describePracticeArea
                  : practiceArea.nome,
                AprovaIniciativaAutomatico: true
              };
              let response;
              try {
                response = await register(
                  values.email,
                  values.email,
                  values.fullname,
                  values.cpfCnpj.replace(/[^\d]+/g, ""),
                  values.password,
                  organization,
                  "P4",
                  socialData && socialData.graphDomain ? socialData.email : "",
                  socialData && !socialData.graphDomain ? socialData.email : ""
                );
                if (response) {
                  setIsLoading(false);
                  setSucess(true);
                  setSubmitting(false);
                  resetForm({});
                  setResetInput(true);
                  setPracticeAreaId(-1);
                  props.register(response);
                  setStatus(
                    intl.formatMessage({
                      id: "AUTH.GENERAL.REGISTER_SUCESS"
                    })
                  );
                }
                window.scrollTo(0, 0);
              } catch (error) {
                setIsLoading(false);
                setSucess(false);
                setSubmitting(false);
                if (error.response && error.response.data) {
                  setStatus(error.response.data);
                } else {
                  setStatus(
                    intl.formatMessage({
                      id: "AUTH.VALIDATION.INVALID_REGISTER"
                    })
                  );
                }
                window.scrollTo(0, 0);
              }
            }
          }}
        >
          {({
            values,
            status,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              {status && (
                <div
                  role="alert"
                  className={
                    sucess ? "alert alert-primary col-12" : "alert alert-danger"
                  }
                >
                  <div className="alert-text">{status}</div>
                </div>
              )}
              {status && sucess ? (
                <div className="kt-login__actions">
                  <Link to="/auth">
                    <button
                      type="button"
                      className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                    >
                      Voltar
                    </button>
                  </Link>
                </div>
              ) : (
                <>
                  <div className="form-group">
                    {!typeRegistration ? (
                      <>
                        <div className="d-flex justify-content-center">
                          <h2 className="title-switch">
                            Como gostaria de participar da Semana Verzel?
                          </h2>
                        </div>
                        <div className="label-switch">
                          Escolha uma das opções abaixo:
                        </div>
                      </>
                    ) : null}
                    <Switch
                      chaves={[
                        '<div class="d-flex justify-content-center"><h3>Participante</h3></div> <div class="title-switch"><spam class="text-butoon-switch">Pessoa que deseja participar das iniciativas apresentadas pela 7ª Semana Verzel</spam></div>',
                        '<div class="d-flex justify-content-center"><h3>Organizador</h3></div> <div class="title-switch"><spam class="text-butoon-switch">Empresa/Autônomo que deseja contribuir com iniciativas para a 7ª Semana Verzel </spam></div>'
                      ]}
                      valores={["Participante", "Organizador"]}
                      setResponseSwitch={e => {
                        setResponseSwitch(e);
                        setResetSwitch(false);
                      }}
                      reset={resetSwitch}
                      icons={["person", "assignmentInd"]}
                    ></Switch>
                  </div>

                  {typeRegistration ? (
                    <>
                      <div className="form-group mt-0 mb-0">
                        <TextField
                          margin="normal"
                          label="Nome completo"
                          className="kt-width-full"
                          name="fullname"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={
                            values.fullname === null && socialData
                              ? (values.fullname = socialData.name)
                              : values.fullname
                              ? values.fullname
                              : ""
                          }
                          helperText={touched.fullname && errors.fullname}
                          error={Boolean(touched.fullname && errors.fullname)}
                        />
                      </div>

                      <div className="form-group mb-0">
                        <TextField
                          margin="normal"
                          label="CPF"
                          className="kt-width-full"
                          name="cpfCnpj"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={verifyCpfCnpj(values.cpfCnpj).cpfCnpjFormat}
                          helperText={touched.cpfCnpj && errors.cpfCnpj}
                          error={Boolean(touched.cpfCnpj && errors.cpfCnpj)}
                        />
                      </div>

                      <div className="form-group mb-0">
                        <TextField
                          label="Email"
                          margin="normal"
                          className="kt-width-full"
                          name="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={
                            values.email === null && socialData
                              ? (values.email = socialData.email)
                              : values.email
                              ? values.email
                              : ""
                          }
                          helperText={touched.email && errors.email}
                          error={Boolean(touched.email && errors.email)}
                        />
                      </div>

                      {/* <div className="form-group mb-0">
                        <TextField
                          margin="normal"
                          label="Nome de usuario"
                          className="kt-width-full"
                          name="username"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={
                            values.email === null && socialData
                              ? (values.email = socialData.email)
                              : values.email
                              ? values.email
                              : ""
                          }
                          helperText={touched.username && errors.username}
                          error={Boolean(touched.username && errors.username)}
                        />
                      </div> */}

                      <div className="form-group mb-0">
                        <TextField
                          type="password"
                          margin="normal"
                          label="Senha"
                          className="kt-width-full"
                          name="password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.password}
                          helperText={touched.password && errors.password}
                          error={Boolean(touched.password && errors.password)}
                        />
                      </div>

                      <div className="form-group mb-0">
                        <TextField
                          type="password"
                          margin="normal"
                          label="Confirme a Senha"
                          className="kt-width-full"
                          name="confirmPassword"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.confirmPassword}
                          helperText={
                            touched.confirmPassword && errors.confirmPassword
                          }
                          error={Boolean(
                            touched.confirmPassword && errors.confirmPassword
                          )}
                        />
                      </div>
                      {typeRegistration === "Organizador" ? (
                        <div>
                          <div
                            className={
                              !practiceAreaId
                                ? "form-group pt-5 pb-4"
                                : "form-group pt-5"
                            }
                          >
                            {!values.pessoaFisica ? (
                              <>
                                <div className="selectMensage">
                                  <p className="d-flex justify-content-start">
                                    Digite o nome da sua organização para
                                    verificar se ela já foi cadastrada. Caso não
                                    tenha sido não tem problema, digite o nome e
                                    clique na opção "Criar a organização", após
                                    isso, preencha os demais campos para que
                                    possamos inclui-la.
                                  </p>
                                </div>
                                <SelectSearchComponent
                                  required={requiredOrganizacao}
                                  resetInput={resetInput}
                                  handleChange={e => {
                                    setNewOption(false);
                                    setResetInput(false);
                                    setRequiredOrganizacao({
                                      status: false,
                                      color: colorConfig.organizacaoColor
                                    });
                                    if (e.obj) {
                                      setOrganizationName(e.obj.nome);
                                      setPracticeAreaId(e.obj.idAreaAtuacao);
                                    } else {
                                      setOrganizationName(e.value);
                                      setPracticeAreaId(null);
                                    }
                                  }}
                                  setNewOption={e => setNewOption(e)}
                                  newOption={newOption}
                                  className="kt-width-full"
                                  label="Digite o nome da organização"
                                  url={`/organizacao/search-name-cnpj?isP4=${true}&str=`}
                                  convertObject={obj => ({
                                    id: obj.id,
                                    value: obj.id,
                                    label: `${obj.nome} ${
                                      obj.cnpj
                                        ? "-" +
                                          verifyCpfCnpj(obj.cnpj).cpfCnpjFormat
                                        : ""
                                    }`,
                                    obj
                                  })}
                                />
                                {requiredOrganizacao.status ? (
                                  <div className="selectError">
                                    <p className="d-flex justify-content-start">
                                      Campo obrigatório
                                    </p>
                                  </div>
                                ) : null}
                              </>
                            ) : (
                              <div className="form-group mt-0 mb-0">
                                <div className="selectLabel">
                                  <p className="d-flex justify-content-start">
                                    Nome da organização
                                  </p>
                                </div>
                                <TextField
                                  margin="normal"
                                  label=""
                                  className="kt-width-full mt-0"
                                  name="fullname"
                                  value={values.fullname}
                                  disabled
                                />
                              </div>
                            )}
                            {!practiceAreaId ? (
                              <div>
                                <div className="form-group mb-0 mt-5">
                                  <InputLabel>
                                    Selecione a sua área de atuação
                                  </InputLabel>
                                  <Select
                                    name="action-area"
                                    className="kt-width-full col-12"
                                    onBlur={handleBlur}
                                    onChange={e =>
                                      setPracticeArea(e.target.value)
                                    }
                                    value={practiceArea}
                                    error={Boolean(
                                      touched.practiceArea &&
                                        errors.practiceArea
                                    )}
                                  >
                                    {practiceAreaData.map(item => (
                                      <MenuItem key={item.id} value={item}>
                                        {item.nome}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </div>

                                {practiceArea.nome === "Outros" ? (
                                  <div className="form-group mb-0 mt-2">
                                    <TextField
                                      margin="normal"
                                      label="Descreva a área de atuação"
                                      className="kt-width-full"
                                      name="describePracticeArea"
                                      value={values.describePracticeArea}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      helperText={
                                        touched.describePracticeArea &&
                                        errors.describePracticeArea
                                      }
                                      error={Boolean(
                                        touched.describePracticeArea &&
                                          errors.describePracticeArea
                                      )}
                                    />
                                  </div>
                                ) : null}
                                <div className="form-group mb-0 mt-2">
                                  <TextField
                                    margin="normal"
                                    label="CNPJ da organização"
                                    className="kt-width-full"
                                    name="cpfCnpjOrg"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={
                                      verifyCpfCnpj(values.cpfCnpjOrg)
                                        .cpfCnpjFormat
                                    }
                                    helperText={
                                      touched.cpfCnpjOrg && errors.cpfCnpjOrg
                                    }
                                    error={Boolean(
                                      touched.cpfCnpjOrg && errors.cpfCnpjOrg
                                    )}
                                  />
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : null}

                      {typeRegistration === "Organizador" ? (
                        <FormControlLabel
                          label="Sou organizador pessoa fisica"
                          control={
                            <Checkbox
                              name="pessoaFisica"
                              onBlur={handleBlur}
                              onChange={e => {
                                handleChange(e);
                                setRequiredOrganizacao({
                                  status: false,
                                  color: colorConfig.organizacaoColor
                                });
                              }}
                              checked={values.pessoaFisica}
                            />
                          }
                        />
                      ) : null}

                      {/* <div className="form-group mt-1"> */}
                        {/* <FormControlLabel */}
                        {/* label={ */}
                        {/* <>
                          Eu li e concordo com os{" "}
                          <Link
                            to="/terms"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="kt-login__terms"
                          >
                            Termos & Condições
                          </Link>
                        </> */}
                        {/* } */}
                        {/* control={
                              <Checkbox
                                name="acceptTerms"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                checked={values.acceptTerms}
                              />
                           } */}
                        {/* /> */}
                      {/* </div> */}

                      <div className="kt-login__actions">
                        <button
                          onClick={() => {
                            setResponseSwitch("");
                            setResetSwitch(true);
                          }}
                          type="button"
                          className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                        >
                          Voltar
                        </button>

                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="btn btn-primary btn-elevate kt-login__btn-primary"
                        >
                          Cadastrar
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="kt-login__actions">
                      <Link to="/auth">
                        <button
                          type="button"
                          className="btn btn-secondary btn-elevate kt-login__btn-secondary"
                        >
                          Voltar
                        </button>
                      </Link>
                    </div>
                  )}
                </>
              )}
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
