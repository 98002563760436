import React from "react";
import './Style/index.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Icon } from '@material-ui/core';

class SwitchComponent extends React.Component {
  initialState = {
    response: ""
  };

  alterResponse(dado) {
    this.setState({ response: dado })
    this.props.setResponseSwitch(dado)
  }

  state = this.initialState;
  render() {
    const { chaves, valores, reset, icons } = this.props;
    return (
      <div className="containerSwitch">
        {
          !reset ?
            <div className="containerSwitch">
              <div className="row">
                {
                  this.state.response === valores[0] ?
                    <div className="buttonClick col-12">
                      <div className="buttonLabelClick">{valores[0]}</div>
                    </div>
                    :
                    <div className="buttonClick col-12">
                      <div className="buttonLabelClick">{valores[1]}</div>
                    </div>
                }
              </div>
            </div> :
            <div className="containerSwitch">
              <div className="row">
                <div onClick={() => this.alterResponse(valores[0])} className="buttonLeftG col-6">
                  {icons[0] ? <div className="icon-switch"><Icon fontSize="large">{icons[0]}</Icon></div> : null}
                  <div className="buttonLabelG" dangerouslySetInnerHTML={{ __html: chaves[0] }}></div>
                </div>
                <div onClick={() => this.alterResponse(valores[1])} className="buttonRightG col-6">
                  <div>{icons[1] ? <div className="icon-switch"><Icon fontSize="large">{icons[1]}</Icon></div> : null}</div>
                  <div className="buttonLabelG" dangerouslySetInnerHTML={{ __html: chaves[1] }}></div>
                </div>
              </div>
            </div>
        }
      </div>
    )
  }
}
export default SwitchComponent
