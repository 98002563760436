import React from 'react';


function Footer() {


  return (
   <div/>
  );
}

export default Footer;
