import './components/styles.scss';
import './styles.scss';

import React, { useEffect, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { BiCheck, BiLayer } from 'react-icons/bi';
import { FaSearch } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { MdPerson } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import Swal from 'sweetalert2';

import { EmployeeTypeOptions } from '../../../../constants/EmployeeTypeOptions';
import { USER_TYPES } from '../../../../enums/UsersTypes';
import { Portlet, PortletHeader } from '../../../../partials/content/Portlet';
import { allocationTypes } from '../../../../store/alocacao';
import useDocumentTitle from '../../../../utils/useDocumentTitle';
import Loading from '../../../home/components/Loading';
import { Divider } from './components/components';
import { cardTypes, GroupCardsByType } from './components/GroupCardsByType';
import { GetContextAllocation } from './context/AllocationContext';
import { CardAllocation, Page } from './styles';
import { generateColor, statusAlocacao } from './utils';

import globalColors from '../../../../../colors'

const colorConfig = globalColors.app.pages.admin.pages.allocation;

export const COLOR_TYPE = {
  RED: "red",
  GREEN: "green",
  ORANGE: "orange",
  GRAY: "gray",
  BLACK: "black"
};

export const colors = {
  red: colorConfig.red,
  green: colorConfig.green,
  black: colorConfig.black,
  orange: colorConfig.orange,
  gray: colorConfig.gray
};

export const ProjectTypeOptions = [
  {
    value: 0,
    label: "Sustentação"
  },
  {
    value: 1,
    label: "Projeto"
  },
  {
    value: 2,
    label: "Consultoria"
  },
  {
    value: 3,
    label: "Alocação"
  }
];

const AllocationPage = () => {
  useDocumentTitle("Gerenciar Alocação - Admin");

  const dispatch = useDispatch();

  const {
    employees,
    technologies,
    groupedOptions,
    allocations,
    months,
    years,
    jobs,
    yearSelected,
    handleChangeYear
  } = GetContextAllocation();

  const onChangeYear = year => {
    dispatch({ type: allocationTypes.Year, payload: year });
    handleChangeYear(year);
  };

  const [filterLeads, setFilterLeads] = React.useState([]);
  const [filterTechs, setFilterTechs] = React.useState([]);

  return (
    <Page className="col-xl-12">
      <Portlet fluidHeight>
        <PortletHeader title="Alocação Mensal" />
      </Portlet>
      <div className="containerArea">
        <Row>
          <Select
            name="ano"
            classNamePrefix="select2"
            className="field"
            placeholder="Ano"
            isClearable={false}
            options={years}
            onChange={value => onChangeYear(value.value)}
            value={{ value: yearSelected, label: yearSelected }}
          />
          <Select
            name="techLead"
            classNamePrefix="select2"
            className="field"
            placeholder="Líder do Projeto"
            isClearable={false}
            isMulti
            options={groupedOptions}
            onChange={values => setFilterLeads(values ?? [])}
            value={filterLeads}
          />
          <Select
            name="techs"
            classNamePrefix="select2"
            className="field"
            placeholder="Tecnologias Requeridas"
            isClearable={false}
            isMulti
            options={technologies}
            onChange={values => setFilterTechs(values ?? [])}
            value={filterTechs}
          />
        </Row>
        <MonthsArea months={months} />
        <Row>
          <FiltersEmployee
            employees={employees}
            jobs={jobs}
            filterTech={filterTechs}
          />
          <Divider />
          <FiltersProject
            projects={allocations}
            filterTech={filterTechs}
            filterLead={filterLeads}
          />
        </Row>
      </div>
    </Page>
  );
};

const MonthsArea = ({ months }) => {
  const { monthSelected, handleChangeMonth } = GetContextAllocation();

  const dispatch = useDispatch();
  const onChangeMonth = month => {
    dispatch({ type: allocationTypes.Month, payload: month });
    handleChangeMonth(month);
  };

  function handleAddZero(length) {
    let formatted;

    if (length < 10) {
      formatted = "0" + length;
    } else {
      formatted = length;
    }

    return formatted;
  }

  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
  });

  return (
    <>
      <Row className="wrap months">
        {months && months?.length
          ? months?.map(month => (
            <CardAllocation
              key={Math.random()}
              backgroundColor={
                generateColor(month.alocadas, month.planejadas).color
              }
            >
              <button
                className="button-allocation-card"
                onClick={() => onChangeMonth(month.month)}
              >
                {month.labelMonth} - {month.alocadas}H /{" "}
                <span>{month.planejadas}H</span>
                {month.month === monthSelected && (
                  <span className="icon-check-month">
                    <BiCheck />
                  </span>
                )}
              </button>

              <div
                className="card-divider"
                style={{
                  borderTop: `0.5px solid ${generateColor(month.alocadas, month.planejadas).color ===
                    colorConfig.red
                    ? colorConfig.redAlternative
                    : generateColor(month.alocadas, month.planejadas)
                      .color === colorConfig.green
                      ? colorConfig.greenAlternative
                      : colorConfig.genericAlternative
                    }`
                }}
              />

              <ReactTooltip
                id={`finished-projects-month-${month.month}`}
                className="tooltip"
                effect="solid"
                place="right"
                type="light"
                style={{ maxHeight: "10px", overflow: "scroll" }}
                border
                anchorSelect={`#clickable`}
                clickable
              >
                <strong className="tooltip-text">Projetos finalizados - {month.labelMonth} ( {handleAddZero(month.projetosFinalizados.length)} )</strong>
                <br />
                {
                  month.projetosFinalizados.map(project => (
                    <>
                      <span style={{ fontWeight: "normal" }}>{project.name}</span>
                      <br />
                    </>
                  ))
                }
              </ReactTooltip>
              <div
                id={`clickable`}
                data-for={`finished-projects-month-${month.month}`}
                data-tip="999"
                className="button-all-projects"
                style={{ cursor: "pointer" }}
              >
                <span>
                  Projetos Finalizados (
                  {handleAddZero(month.projetosFinalizados.length)})
                </span>
              </div>

              <span className="content-card">
                {/*
                Custo: {formatter.format(month.custoAlocacao)} /{" "}
                {formatter.format(month.custoProposta)}
                  */}
              </span>
            </CardAllocation>
          ))
          : null}
      </Row>
    </>
  );
};

const Header = ({
  icon,
  title,
  actions,
  saldoHorasDisponiveis,
  horasTetoMensaisSomadas
}) => {
  const { api, monthSelected, yearSelected } = GetContextAllocation();

  const [loading, setLoading] = useState(false);

  const callAPI = async configAPI => {
    setLoading(true);

    try {
      const response = await api.makeHttpRequest({
        url: configAPI.url,
        method: configAPI.method,
        data: {
          month: monthSelected,
          year: yearSelected,
          Active: true
        }
      });

      if (response) {
        setLoading(false);
        return Swal.fire({
          icon: "success",
          text: "Socitação efetuada com sucesso!"
        });
      }
    } catch (error) {
      setLoading(false);
      return Swal.fire({
        icon: "warning",
        text: "Falha ao enviar solicitação!"
      });
    }
  };

  return (
    <>
      <Loading isLoading={loading} />
      <div className="header">
        <div className="title">
          <div className="icon">{icon}</div>
          <label>{title}</label>
        </div>
        <div className="actions">
          {saldoHorasDisponiveis && horasTetoMensaisSomadas ? (
            <strong>
              {saldoHorasDisponiveis}H / {horasTetoMensaisSomadas}H
            </strong>
          ) : null}
          {actions &&
            actions.length &&
            actions.map(action => (
              <button key={Math.random()} onClick={() => callAPI(action.api)}>
                {action?.icon} <span>{action?.name}</span>
              </button>
            ))}
        </div>
      </div>
    </>
  );
};

const FiltersProject = ({ projects, filterLead }) => {
  const [status, setStatus] = React.useState([]);
  const [search, setSearch] = React.useState("");

  const actions = [
    {
      name: "Enviar alocação",
      icon: <HiOutlineMail />,
      api: {
        url: "/allocation/sendMail",
        method: "POST"
      }
    }
  ];

  return (
    <div style={{ width: "48%" }}>
      <Header icon={<BiLayer />} title="Projetos" actions={actions} />
      <Row>
        <Select
          classNamePrefix="select2"
          className="field"
          placeholder="Status da Alocacao"
          isClearable={false}
          isMulti
          onChange={values => setStatus(values ? values : [])}
          options={statusAlocacao}
          value={status}
        />
        <Form.Group>
          <Form.Control
            placeholder="Busca"
            onChange={event => setSearch(event.target.value)}
            value={search}
          />
          <FaSearch />
        </Form.Group>
      </Row>
      <Row className="allocationColumn" style={{ marginTop: "24px" }}>
        {projects && projects.length
          ? ProjectTypeOptions.map(type => (
            <GroupCardsByType
              key={Math.random()}
              arrayToGroup={projects.filter(
                proj =>
                  proj.name.toLowerCase().includes(search.toLowerCase()) &&
                  (status.length === 0 ||
                    status.find(s => s.value === proj.status)) &&
                  (filterLead.length === 0 ||
                    filterLead.find(lead => lead.label === proj.leadName))
              )}
              type={type.value}
              cardType={cardTypes.PROJETO}
              limitNumber={2}
              horasAlocadasMensaisSomadas={undefined}
              horasTetoMensaisSomadas={undefined}
            />
          ))
          : null}
      </Row>
    </div>
  );
};

const FiltersEmployee = ({ employees, jobs, filterTech }) => {
  const [status, setStatus] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [items, setItems] = React.useState([]);

  useEffect(() => {
    const arrayJobsIncludes = jobs.filter(item =>
      employees?.filter(i => i.id != item.id)
    );
    setItems([...employees, ...arrayJobsIncludes]);
  }, [jobs, employees]);

  const [filteredEmployees, setFilteredEmployees] = React.useState(items);

  //Filtrando os Employees e criando um State para manipular o Filtro
  useEffect(() => {
    const result = items.filter(
      emp =>
        emp.name.toLowerCase().includes(search.toLowerCase()) &&
        (status.length === 0 || status.find(s => s.value === emp.status)) &&
        (filterTech.length === 0 ||
          filterTech.find(tech => emp.technologies.includes(tech.label)))
    );
    const resultTeste = result.length === 0 ? [] : result;

    setFilteredEmployees(resultTeste);
  }, [status, search, filterTech]);

  useEffect(() => {
    if (items) setFilteredEmployees(items);
  }, [items]);

  //Somando as horas Alocadas - [Testar na Tela os Valores para Concluir]
  const listaAlocacoes = filteredEmployees
    .filter(item => item.type != USER_TYPES.JOB)
    .map(item => item.allocations)
    .filter(item => item?.length > 0);

  const horasAlocadasMensaisSomadas = listaAlocacoes
    .map(item =>
      item.map(item => item.hoursAllocated).reduce((sum, item) => sum + item, 0)
    )
    .reduce((sum, item) => sum + item, 0);

  //Somando as horas Teto
  const horasTetoMensaisSomadas = filteredEmployees
    .filter(item => item.type != USER_TYPES.JOB)
    .reduce((sum, item) => sum + item.tetoHorasMensais, 0);

  //Saldo de Horas
  const saldoHorasDisponiveis =
    horasTetoMensaisSomadas - horasAlocadasMensaisSomadas;
  return (
    <div style={{ width: "48%" }}>
      <Header
        icon={<MdPerson />}
        title="Profissionais"
        saldoHorasDisponiveis={saldoHorasDisponiveis}
        horasTetoMensaisSomadas={horasTetoMensaisSomadas}
      />
      <Row>
        <Select
          classNamePrefix="select2"
          className="field"
          placeholder="Status da Alocacao"
          isClearable={false}
          isMulti
          onChange={values => setStatus(values ? values : [])}
          options={statusAlocacao}
          value={status}
        />
        <Form.Group>
          <Form.Control
            placeholder="Busca"
            onChange={event => setSearch(event.target.value)}
            value={search}
          />
          <FaSearch />
        </Form.Group>
      </Row>

      <Row className="allocationColumn" style={{ marginTop: "24px" }}>
        {items && items.length
          ? EmployeeTypeOptions.map(type => (
            <GroupCardsByType
              key={Math.random()}
              arrayToGroup={filteredEmployees}
              type={type.value}
              cardType={cardTypes.USUARIO}
              limitNumber={4}
              horasAlocadasMensaisSomadas={horasAlocadasMensaisSomadas}
              horasTetoMensaisSomadas={horasTetoMensaisSomadas}
            />
          ))
          : null}
        <GroupCardsByType
          key={Math.random()}
          arrayToGroup={filteredEmployees}
          type={cardTypes.JOB}
          cardType={cardTypes.JOB}
          limitNumber={4}
          horasAlocadasMensaisSomadas={horasAlocadasMensaisSomadas}
          horasTetoMensaisSomadas={horasTetoMensaisSomadas}
        />
      </Row>
    </div>
  );
};

export default AllocationPage;
