import store from "../store/store";

export const isCollaborator = () => {
    const { auth } = store.getState();
    const user = auth?.user;

    if (!!user && !!user.userFunction.name && user.userFunction.name.toUpperCase() === 'COLABORADOR')
        return true;

    return false;
}