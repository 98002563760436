import React, { useEffect } from "react";
import { ProjectTypeOptions } from "../..";
import { EmployeeTypeOptions } from "../../../../../../constants/EmployeeTypeOptions";
import { LevelUserAreaProvider } from "../../context/LevelUserAreaContext";
import { GroupCardContainer, GroupedArrayShowMoreButton } from "../components";
import { ProjectCardAllocation } from "../ProjectCardAllocation";
import { UserCardAllocation } from "../UserCardAllocation";

export const cardTypes = {
  PROJETO: "PROJETO",
  USUARIO: "USUARIO",
  JOB: "JOB"
};

export const GroupCardsByType = ({
  arrayToGroup,
  cardType,
  limitNumber,
  type,
  horasAlocadasMensaisSomadas,
  horasTetoMensaisSomadas
}) => {
  const [showAll, setShowAll] = React.useState(false);

  const [arrayToRender, setArrayToRender] = React.useState([]);

  const [filteredArray, setFilteredArray] = React.useState([]);

  const [typeName, setTypeName] = React.useState([]);

  useEffect(() => {
    if (cardType === cardTypes.USUARIO) {
      setTypeName(
        EmployeeTypeOptions.find(employeeType => employeeType.value === type)
          .label
      );
      let employeers = [];

      for (const user of arrayToGroup) {
        if (user.employeeType === type && user.type !== cardTypes.JOB) {
          employeers = [user, ...employeers];
          setFilteredArray(employeers);
        }
      }
    } else if (cardType === cardTypes.PROJETO) {
      setTypeName(
        ProjectTypeOptions.find(tipoProjeto => tipoProjeto.value === type).label
      );
      let projects = [];
      for (const project of arrayToGroup) {
        if (project.tipoProjeto === type) {
          projects = [project, ...projects];
          setFilteredArray(projects);
        }
      }
    } else if (cardType === cardTypes.JOB) {
      setTypeName("Vagas");
      let jobs = [];

      for (const job of arrayToGroup) {
        if (job.type === cardTypes.JOB) {
          jobs = [job, ...jobs];
          setFilteredArray(jobs);
        }
      }
    }
  }, [arrayToGroup, type]);

  useEffect(() => {
    if (filteredArray) {
      setArrayToRender(
        Array.from(
          showAll ? filteredArray : filteredArray.sort(
            function (a, b) {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
            }
          ).slice(0, limitNumber)
        )
      );
    }
  }, [showAll, filteredArray, limitNumber]);

  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <>
      {cardType === cardTypes.USUARIO && arrayToRender.length > 0 && (
        <GroupCardContainer>
          <span
            style={{ fontSize: "18px" }}
          >{`${typeName} (${filteredArray.length})`}</span>
          {arrayToRender.sort(function (a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          }).map(user => (
            <UserCardAllocation
              key={Math.random()}
              employee={user}
              horasTetoMensaisSomadas={horasTetoMensaisSomadas}
              horasAlocadasMensaisSomadas={horasAlocadasMensaisSomadas}
            />
          ))}
          <GroupedArrayShowMoreButton onClick={handleShowAll}>
            {!showAll ? "Ver todos os profissionais" : "Ocultar profissionais"}
          </GroupedArrayShowMoreButton>
        </GroupCardContainer>
      )}

      {cardType === cardTypes.JOB && arrayToRender.length > 0 && (
        <GroupCardContainer>
          <span
            style={{ fontSize: "18px" }}
          >{`${typeName} (${filteredArray.length})`}</span>
          {arrayToRender.map(user => (
            <UserCardAllocation
              key={Math.random()}
              employee={user}
              horasTetoMensaisSomadas={horasTetoMensaisSomadas}
              horasAlocadasMensaisSomadas={horasAlocadasMensaisSomadas}
            />
          ))}
          <GroupedArrayShowMoreButton onClick={handleShowAll}>
            {!showAll ? "Ver todas as vagas" : "Ocultar vagas"}
          </GroupedArrayShowMoreButton>
        </GroupCardContainer>
      )}

      {cardType === cardTypes.PROJETO && arrayToRender.length > 0 && (
        <GroupCardContainer>
          <span
            style={{ fontSize: "18px" }}
          >{`${typeName} (${filteredArray.length})`}</span>
          {arrayToRender.sort(function (a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          }).map(project => (
            <LevelUserAreaProvider project={project} key={Math.random()}>
              <ProjectCardAllocation project={project} />
            </LevelUserAreaProvider>
          ))}
          <GroupedArrayShowMoreButton onClick={handleShowAll}>
            {!showAll ? "Ver todos os projetos" : "Ocultar projetos"}
          </GroupedArrayShowMoreButton>
        </GroupCardContainer>
      )}
    </>
  );
};
