import axios from "axios";
import { VizzionApiService } from "../services/VizzionApiService";

export const REGISTER_URL = "api/user";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
const api = new VizzionApiService();

export async function login(email, password) {
  return api.makeHttpRequest({
    url: '/login',
    method: 'POST',
    data: {
      Login: email,
      Senha: password
    }
  });
}

export async function resendEmail(email) {
  return api.makeHttpRequest({
    url: '/user/resend-mail/'+email,
    method: 'PUT',
  });
}

export async function loginGoogle(email) {
  return api.makeHttpRequest({
    url: `/login/login-with-social?login=${email}`,
    method: 'GET',
  });
}


export async function verificaEmail(email) {
  return api.makeHttpRequest({
    url: `/user/verificar-email-login?login=${email}`,
    method: 'GET',
  });
}



export async function register(
  email,
  nome,
  senha,
  loginGoogle,
  googleId,
  ) {

  const response = await api.makeHttpRequest({
    url: '/user',
    method: 'POST',
    data: {
      email,
      nome,
      senha,
      LoginGoogle: loginGoogle,
      GoogleId: googleId,
    }
  });
 return response;
}

export async function updatePassword({
  currentPassword,
  password,
}) {

  const response = await api.makeHttpRequest({
    url: `/user/update-password`,
    method: 'PUT',
    data: {
      SenhaAtual: currentPassword,
      NovaSenha: password,
    },
  });
  return response;
}

export async function updateUser({
  email,
  nome,
  linkdIn,
  gitHub,
  telefone,
  imgAvatar,
  removeAvatar,
  AceiteDevstars,
  loginGoogle,
  googleID
}) {

  const bodyFormData = new FormData();
  imgAvatar && bodyFormData.append('Avatar.File', imgAvatar);
  email && bodyFormData.append('Email', email);
  nome && bodyFormData.append('Nome', nome);
  linkdIn && bodyFormData.append('LinkedIn', linkdIn);
  telefone && bodyFormData.append('Telefone', telefone);
  gitHub && bodyFormData.append('GitHub', gitHub);
  loginGoogle && bodyFormData.append('LoginGoogle', loginGoogle);
  googleID && bodyFormData.append('GoogleID', googleID);
  AceiteDevstars && bodyFormData.append('AceiteDevstars', AceiteDevstars);
  bodyFormData.append('RemoveAvatar', removeAvatar || false);

  const response = await api.makeHttpRequest({
    url: `/usuario/perfil`,
    method: 'PUT',
    data: bodyFormData,
  });
  return response;
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  return api.makeHttpRequest({
    url: '/user/me'
  });
};
