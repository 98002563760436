import styled from "styled-components";

export const Page = styled.div`
  position: relative;

  .icone,
  svg {
    font-size: 20px;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 16px;
    max-height: 500px;
    overflow-y: scroll;
    padding-right: 5px;

    scrollbar-width: thin;
    scrollbar-color: rgba(109, 109, 109, 0.25) white;

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: white;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(109, 109, 109, 0.25);
      border-radius: 20px;
      border: 3px solid white;
    }
  }

  .modal {
    position: "absolute";
    top: "10%";
    left: "40%";
    width: "100%";
  }
`;

export const CardAllocation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  width: 255px;

  background-color: ${({ backgroundColor }) => backgroundColor} !important;

  border-radius: 4px;
  padding: 9px 16px;

  color: white;

  font-style: normal;
  line-height: 24px;

  font-family: 'Montserrat', sans-serif !important;

  position: relative;

  .icon-check-month {
    position: absolute;
    right: 32px;
    top: 6px;
  }

  .card-divider {
    width: 100%;
  }

  .content-card {
    font-weight: 500;
    font-size: 10px;
  }

  > button {
    background: none;
    font-family: 'Montserrat', sans-serif;
  }

  .button-allocation-card {
    text-transform: uppercase;

    padding: 0 !important;
    margin: 0 !important;

    font-size: 12px;
    font-weight: 400;
  }

  .button-all-projects {
    text-decoration: underline;

    font-size: 11px;
    font-weight: 700;

    padding: 0 !important;
    margin: 0 !important;
  }
`;
