import React from "react";
import { Link, Switch, Route, Redirect } from "react-router-dom";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import logo from '../../assets/logo/logo-login.svg';
import bg2 from '../../assets/background/auth/login-bg1.png';
import bg1 from '../../assets/background/auth/login-bg2.png';
import Login from "./Login";
import Registration from "./Registration";
import Password from './Password';
import PassActions from './Password/enum/Actions';
import Content from "../home/components/Content/Content";
import {shallowEqual, useSelector} from "react-redux";

export default function AuthPage() {
  const { isAuthorized } = useSelector(
    ({ auth, builder: { menuConfig } }) => ({ isAuthorized: auth.user != null }),
    shallowEqual
  );
  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root"
         style={{
          height: '100%',
        }}
      >
        <div
          id="kt_login"
          className=" kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
        >
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div
              className="login-bg-logo kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
            >
              <div className="kt-grid__item login-bg-logo">
                <Content
                  loading={!logo}
                >
                  <div className="kt-login__logo justify-content-center login-bg-logo">
                    <div className="login-bg-and-logo">
                        <img
                          alt="Logo"
                          src={logo}
                          className="login-logo"
                        />
                      <div className="login-bg">
                        <img
                          alt="Logo"
                          src={bg1}
                          className="login-bg-1"
                        />
                        <img
                          alt="Logo"
                          src={bg2}
                          className="login-bg-2"
                        />
                      </div>
                    </div>
                  </div>
                </Content>
              </div>
            </div>

            <div
            style={{padding: 0}}
            className="kt-grid__item kt-grid__item--fluid kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
              <Switch>
                <Route path="/auth/ativar-conta" component={() => <Password action={PassActions.CriarConta} />} />
                <Route path="/auth/recuperar-senha" component={() => <Password action={PassActions.RecuperarSenha} />} />
                <Route path="/auth/cadastrar-senha" component={() => <Password action={PassActions.CadastrarSenha} />} />
                <Route path="/auth/login" component={Login} />
                <Route path="/auth/registration" component={Registration} />
                <Redirect from="/auth" exact={true} to="/auth/login" />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
