import styled from 'styled-components';
import windowSize from '../../constants/WindowSize';

import colors from '../../../colors';

const colorConfig = colors.app.pages.policy;


export const Container = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
  padding: 0 ${({width}) => width >  windowSize.SM ? '10%' : '16px'};
  padding-bottom: 28px;

  > h1 {
    font-size: ${({width}) => width > windowSize.SM ? '32px' : '24px'};
    font-weight: 600;
    margin: 24px 0;
    font-family: "Biennale Regular";
  }

  > p {
    font-size: ${({width}) => width > windowSize.SM ? '18px' : '14px'};
    margin: 8px 0 40px;
    color: ${colorConfig.container.pColor};
    }

     strong {
      color: ${colorConfig.container.strongColor};
    }
`;
