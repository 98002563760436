import reactSelect from "react-select";
import styled from "styled-components";
import color from '../../../../../../../../colors'

export const CustomSelect = styled(reactSelect)`
  .SelectProjectCard__menu,
  .SelectProjectCard__menu-list {
    z-index: 9999;
  }

  .SelectProjectCard__menu-list {
    // metade da altura do Card
    max-height: calc(300px / 2);

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.2);
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.4);
    }

    &::-webkit-scrollbar-thumb:active {
      background: rgba(0, 0, 0, 0.9);
    }
  }

  .SelectProjectCard__menu-list,
  .SelectProjectCard__option,
  .SelectProjectCard__value-container,
  .SelectProjectCard__indicators {
    font-size: 12px;
  }
`;

export const Project = styled.div`
  width: 100%;
  margin: 10px 0;

  .buttonsWrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .button {
      background-color: ${color.app.pages.admin.pages.allocation.components.projectCardAllocation.projectCard.button};
      border: none;
      border-radius: 4px;
      padding: 10px 20px;
    }

    .active {
      background-color: ${color.app.pages.admin.pages.allocation.components.projectCardAllocation.projectCard.active};
      color: white;
    }

    .cancel {
      background-color: transparent;
      color: ${color.app.pages.admin.pages.allocation.components.projectCardAllocation.projectCard.cancel};
      text-decoration: underline;
    }
  }
`;
