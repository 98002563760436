import React, { useEffect } from "react";

import { CustomSelect, Project } from "./styles";
import { LevelsCard } from "./LevelsCard";
import { GetContextAllocation } from "../../../context/AllocationContext";
import { GetContextLevelUserArea } from "../../../context/LevelUserAreaContext";
import styled from "styled-components";

const ProjectCard = ({ project, closeCollapsed }) => {
  const { optionsSelectEmployees } = GetContextAllocation();
  const { saveChanges, saveResponsavel, cancelChanges, sumHoursMatch } = GetContextLevelUserArea();

  const [selectedResponsable, setSelectedResponsable] = React.useState(
    project.responsavel
  );

  useEffect(() => {
    const selected = getResposable()
    saveResponsavel(selected)
    setSelectedResponsable(selected);
  }, []);

  const onChangeResponsable = e => {
    const selected = e ?? getResposable();
    saveResponsavel(selected)
    setSelectedResponsable(selected);
  };

  const getResposable = () => {
    return optionsSelectEmployees
      ? optionsSelectEmployees?.find(
          x => x.value.split(",")[0] == project.responsavel
        )
      : null;
  };

  const onSave = () => {
    saveChanges();
    closeCollapsed();
  }

  const onCancel = () => {
    cancelChanges();
    closeCollapsed();
  }

  return (
    <Project>
      <div className="responsible">
        <CustomSelect
          classNamePrefix="SelectProjectCard"
          onChange={onChangeResponsable}
          value={selectedResponsable}
          noOptionsMessage={() => <p>Não foi encontrado.</p>}
          placeholder="Selecione o Responsável"
          options={optionsSelectEmployees}
        />
      </div>
      <div className="body">
        {project?.estimatedLevels &&
          project.estimatedLevels?.length > 0 &&
          project.estimatedLevels
            .filter(level => level.estimatedHours > 0)
            .map(level => (
              <LevelsCard level={level} key={level.idLevel} />
            ))}
      </div>
      <div className="buttonsWrapper">
        <Submit type="button" className="button active" onClick={onSave} disabled={!sumHoursMatch}>
          Salvar
        </Submit>
        <button type="button" className="button cancel" onClick={onCancel}>
          Cancelar
        </button>
      </div>
    </Project>
  );
};

const Submit = styled.button`
  &:disabled {
    opacity: .6;
  }
`

export { ProjectCard };
